import React, { Fragment, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import { V3MenuHeaderFieldsFragment } from "../../../../../../../graphql-types"
import * as styles from "./accordion.module.scss"
import { useSlugs } from "../../../../../context/slugs-context"
import RightArrow from "../../../../../../../static/vectors/v3/right-arrow.svg"
import AccordionArrow from "../../../../../../../static/vectors/v3/accordion-arrow.svg"

type RenderProps = {
  menu: V3MenuHeaderFieldsFragment
}

const Accordion: React.FC<RenderProps> = ({ menu }) => {
  const { buildInternalLink } = useSlugs()
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState("0px")
  const accordionContent = useRef(null)

  const toggleAccordion = () => {
    setActive(!active)
    setHeight(active === true ? "0px" : `${accordionContent.current.scrollHeight}px`)
  }

  return (
    <li className={styles.accordion} tabIndex={-1}>
      <button className={styles.link} onClick={toggleAccordion}>
        <h2 className={styles.title}>{menu.title}</h2>
        <div className={`${styles.arrow} ${active && styles.rotated}`}>
          <ReactSVG src={RightArrow} />
        </div>
      </button>
      <div ref={accordionContent} style={{ maxHeight: `${height}` }} className={styles.accordionContent}>
        {menu.submenus.map(submenu => {
          if (submenu.containsMultipleLinks) {
            return (
              <Fragment key={submenu.id}>
                <h3 className={styles.submenuTitle}>{submenu.title}</h3>
                {submenu.multipleLinks?.map(link => (
                  <a
                    href={link.isInternalPage ? buildInternalLink(link.internalLink) : link.externalLink}
                    className={styles.submenuLink}
                    key={link.id}
                  >
                    <ReactSVG src={AccordionArrow} className={styles.arrow} />
                    <h4>{link.title}</h4>
                  </a>
                ))}
              </Fragment>
            )
          }
          return (
            <a href={buildInternalLink(submenu.uniqueLink)} className={styles.submenuLink} key={submenu.id}>
              <ReactSVG src={AccordionArrow} className={styles.arrow} />
              <h4>{submenu.title}</h4>
            </a>
          )
        })}
      </div>
    </li>
  )
}

export default Accordion
