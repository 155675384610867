import React, { Fragment } from "react"
import * as config from "../../../../config"
import { useSlugValue } from "../../hooks"

type HrefLangLinksProps = {
  defaultDomain: string
  defaultLocale: string
  locales?: string[]
  slugs?: object[]
  manualSlugs?: string
}

/**
 * This component generates <link> tags for international SEO using "hreflang" attributes.
 *
 * @param {string} defaultDomain - The site's default domain.
 * @param {string} defaultLocale - The site's default locale.
 * @param {string[]} locales - An optional list of locales supported by the site. If not provided, the component will use config.locales.
 * @param {object[]} [slugs] - An optional list of an object's slugs for each locale.
 * @param {string} [manualSlugs] - An optional string of slugs to use instead of the slugs generated from the slugs prop.
 *
 * @example
 * Suppose we have a site supporting the "fr" and "en" locales, with "fr" being the default.
 *
 * ```jsx
 * <HrefLangLinks
 *    defaultDomain="https://keolis.com/"
 *    locales={data.datoCmsGlobalConfiguration.locales}
 *    defaultLocale={pageContext.defaultLocale}
 *    slugs={[
 *      pageContext.slugs._allNewsroomPageSlugLocales,
 *      pageContext.slugs._allNewsPageSlugLocales,
 *      pageContext.allSlugLocales,
 *    ]}
 * />
 * ```
 *
 * The component would generate the following tags:
 *
 * ```html
 * <link rel="alternate" hreflang="en" href="https://keolis.com/en/newsroom-en/news/keolis-contracts-renewed-in-chauny-and-dole-france" data-gatsby-head="true">
 * <link rel="alternate" hreflang="fr" href="https://keolis.com/newsroom/actualites/keolis-renouvele-a-chauny-et-a-dole-france" data-gatsby-head="true">
 * <link rel="alternate" hreflang="x-default" href="https://keolis.com/newsroom/actualites/keolis-renouvele-a-chauny-et-a-dole-france" data-gatsby-head="true">
 * ```
 */

export const HrefLangLinks: React.FC<HrefLangLinksProps> = ({
  defaultDomain,
  locales,
  defaultLocale,
  slugs,
  manualSlugs,
}) => {
  const generatePath = (locale: string) => {
    defaultDomain = defaultDomain.endsWith("/") ? defaultDomain : defaultDomain + "/"
    const basePath = defaultLocale === locale ? defaultDomain : `${defaultDomain}${locale}/`
    if (!slugs && !manualSlugs) {
      return basePath
    }
    if (manualSlugs) {
      manualSlugs = manualSlugs.startsWith("/") ? manualSlugs.substring(1) : manualSlugs
      manualSlugs = manualSlugs.endsWith("/") ? manualSlugs : manualSlugs + "/"
      return `${basePath}${manualSlugs}`
    }
    const pathSlugs = slugs?.map(slug => {
      return useSlugValue(slug, locale)
    })
    return `${basePath}${pathSlugs.join("/")}/`
  }

  locales = locales || config.locales

  return (
    <>
      {locales.map((locale, index) => {
        const path = generatePath(locale)
        return (
          <Fragment key={index}>
            <link rel="alternate" hrefLang={locale} href={path} />
            {defaultLocale === locale && <link rel="alternate" hrefLang="x-default" href={path} />}
          </Fragment>
        )
      })}
    </>
  )
}
