import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { SocialNetworksColumnFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./social-networks-column.module.scss"

type RenderProps = {
  title?: string
  column: SocialNetworksColumnFieldsFragment[]
}

const SocialNetworksColumn: React.FC<RenderProps> = ({ title, column }) => (
  <div className={styles.column}>
    {title && <span className={styles.title}>{title}</span>}
    <ul className={styles.iconsContainer}>
      {column.map((item, index) => (
        <li key={index}>
          <a href={item.socialMediaLink} target="_blank">
            <ReactSVG
              src={item.socialMediaLogo.url}
              className={styles.pictogram}
              beforeInjection={svg => svg.setAttribute("alt", item.socialMediaLogo.alt || "")}
            />
          </a>
        </li>
      ))}
    </ul>
  </div>
)

export const fragment = graphql`
  fragment SocialNetworksColumnFields on DatoCmsV3FooterSocialNetworkColumn {
    socialMediaLink
    socialMediaLogo {
      alt
      url
    }
  }
`

export default SocialNetworksColumn
