import React from "react"
import * as styles from "./search-results-items.module.scss"
import { SearchState } from "../search-results"
import FormInput from "../form-input/form-input"

type RenderProps = {
  data: {
    attributes: {
      body_excerpt: string
      title: string
      url: string
    }
  }[]
  searchState: SearchState
  searchContent: string
  label: string
  searchTerms: string
  placeholder: string
  onChange: (value: string) => void
}

const SearchResultsItems: React.FC<RenderProps> = ({
  data,
  searchState,
  searchContent,
  label,
  searchTerms,
  placeholder,
  onChange,
}) => {
  return (
    <div className={styles.containerSearchResults}>
      <FormInput searchTerms={searchTerms} placeholder={placeholder} onChange={onChange} centered={true} />

      {searchState === SearchState.Success && (
        <>
          <h2 className={styles.resultsSentence}>
            {data.length}&nbsp;{label}&nbsp;“{searchContent}”
          </h2>
          <div className={styles.searchResultWrapper}>
            {data.map(item => (
              <div className={styles.searchResult} key={item.attributes.url}>
                {item.attributes.title && (
                  <a href={item.attributes.url} className={styles.title}>
                    <span>{item.attributes.title}</span>
                  </a>
                )}
                <span className={styles.body}>{item.attributes.body_excerpt}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default SearchResultsItems
