import React, { useEffect, useRef } from "react"
import { ReactSVG } from "react-svg"
import * as styles from "./card-svg.module.scss"
import { useFormatDate, useIsMobile } from "../../../../hooks"
import { useLocale } from "../../../../context/locale-context"

type RenderProps = {
  title: string
  date: string
  backgroundSvg: string
  backgroundColor?: string
  backgroundColorBis?: string
}

const CardSvg: React.FC<RenderProps> = ({ title, date, backgroundSvg, backgroundColor, backgroundColorBis }) => {
  const { locale } = useLocale()
  const isMobile = useIsMobile()

  return (
    <div className={styles.card}>
      <ReactSVG
        src={backgroundSvg}
        afterInjection={svg => {
          svg.querySelector("rect").style.fill = backgroundColor
          const paths = svg.querySelectorAll("path")
          paths.forEach(path => (path.style.fill = backgroundColorBis))

          if (isMobile) {
            const cardDiv = svg.closest(`.${styles.card}`)
            if (cardDiv) {
              const { width, height } = cardDiv.getBoundingClientRect()
              svg.setAttribute("viewBox", `0 0 ${width} ${height}`)
              svg.setAttribute("width", `${width}px`)
              svg.setAttribute("height", `${height}px`)
              const rects = svg.querySelectorAll("rect")
              rects.forEach(rect => {
                rect.setAttribute("width", `${width}px`)
                rect.setAttribute("height", `${height}px`)
              })
            }
          }
        }}
      />

      <div className={styles.cardContent}>
        <div className={styles.date}>{useFormatDate(date, locale)} </div>
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        <div />
      </div>
    </div>
  )
}

export default CardSvg
