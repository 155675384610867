import React, { useEffect, useState } from "react"
import { buildClient } from "@datocms/cma-client-browser"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, SearchResultsQuery } from "../../../../graphql-types"
import * as config from "../../../../config"
import * as styles from "./search-results.module.scss"
import { StringParam, useQueryParam } from "use-query-params"
import { useFormatLocale, useIsMobile, useSlugValue } from "../../hooks"
import Layout from "../../components/layout/layout"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import NoSearchResults from "./no-search-results/no-search-results"
import SearchResultsItems from "./search-results-items/search-results-items"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

export enum SearchState {
  NoSearch,
  Searching,
  Success,
  Error,
}

type RenderProps = {
  data: SearchResultsQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const SearchResults: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const client = buildClient({ apiToken: config.search_token })
  const [searchResults, setSearchResults] = useState([])
  const [searchState, setSearchState] = useState(SearchState.NoSearch)
  const [searchTerms, setSearchTerms] = useQueryParam("query", StringParam)

  useEffect(() => {
    if (!searchTerms) return

    let allResults = []
    const fetchResults = async () => {
      for (let offset = 0, searching = true; searching; offset += 100) {
        await client.searchResults
          .rawList({
            filter: {
              fuzzy: true,
              query: searchTerms,
              build_trigger_id: "Production",
              locale: pageContext.locale,
            },
            page: {
              limit: 100,
              offset: offset,
            },
          })
          .then(
            ({ data: results }) => {
              if (results.length < 100) {
                searching = false
              }
              allResults.push(...results)
            },
            error => {
              console.error(error)
              setSearchState(SearchState.Error)
              searching = false
            }
          )
      }
    }

    fetchResults().then(() => {
      setSearchResults(allResults)
      setSearchState(SearchState.Success)
    })
  }, [searchTerms])

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allSearchPageSlugLocales, pageContext.locale)}
      pageTemplate="search results"
    >
      <section className={styles.container}>
        {!isMobile && (
          <Breadcrumb
            title={data.datoCmsHeader.resultsPageTitle}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
          />
        )}
        {searchState === SearchState.Searching && (
          <p className={styles.message}>{data.datoCmsHeader.searchInProgressMessage}</p>
        )}
        {searchState === SearchState.Error && (
          <p className={`${styles.message} ${styles.error}`}>{data.datoCmsHeader.errorMessage}</p>
        )}
        {searchState === SearchState.Success && searchResults.length === 0 && (
          <NoSearchResults data={data.datoCmsHeader} searchTerms={searchTerms} onChange={setSearchTerms} />
        )}
        {(searchState === SearchState.NoSearch ||
          (searchState === SearchState.Success && searchResults.length > 0)) && (
          <SearchResultsItems
            data={searchResults}
            searchState={searchState}
            searchContent={searchTerms}
            label={data.datoCmsHeader.resultsFor}
            searchTerms={searchTerms}
            placeholder={data.datoCmsHeader.placeholder}
            onChange={setSearchTerms}
          />
        )}
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsHeader.resultsPageTitle}
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    <meta name="viewport" content="width=device-width" />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allSearchPageSlugLocales]}
      />
    )}
  </>
)

export const pageQuery = graphql`
  query SearchResults($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsHeader(locale: $locale) {
      ...NoSearchResultsFields
    }
  }
`

export default SearchResults
