import React from "react"
import * as styles from "./details.module.scss"
import { InternalExternalLinkBlockFieldsFragment } from "../../../../../../../graphql-types"
import { useSlugs } from "../../../../../context/slugs-context"

type RenderProps = {
  links: InternalExternalLinkBlockFieldsFragment[]
}

const Details: React.FC<RenderProps> = ({ links }) => {
  const { buildInternalLink } = useSlugs()

  return (
    <div className={styles.details}>
      {links?.map(link => (
        <a
          href={link.isInternalPage ? buildInternalLink(link.internalLink) : link.externalLink}
          className={styles.linkTitle}
          key={link.id}
        >
          <span className={styles.spanAnimated}>{link.title}</span>
        </a>
      ))}
    </div>
  )
}

export default Details
