import React from "react"
import { graphql } from "gatsby"
import {
  KeyNumberColoredBackgroundFieldsFragment,
  KeyNumberColoredFieldsFragment,
  KeyNumberSimpleFieldsFragment,
} from "../../../../../graphql-types"
import * as styles from "./key-numbers.module.scss"

type RenderProps = {
  data: KeyNumberSimpleFieldsFragment | KeyNumberColoredFieldsFragment | KeyNumberColoredBackgroundFieldsFragment
}

const KeyNumbers: React.FC<RenderProps> = ({ data }) => {
  const getKeyNumbersStyle = () => {
    switch (data.__typename) {
      case "DatoCmsKeyNumberSimple":
        return `${styles.keyNumbers} ${styles.simpleFormat}`
      case "DatoCmsKeyNumberColored":
        return `${styles.keyNumbers} ${styles.coloredNumbersFormat}`
      case "DatoCmsKeyNumberColoredBackground":
        return `${styles.keyNumbers} ${styles.coloredBackgroundFormat}`
    }
  }

  const buildKeyNumbers = () => {
    return data.keyNumberList.map(keyNumber => {
      return (
        <li key={keyNumber.id} className={styles.keyNumber}>
          <div className={styles.value} style={{ backgroundColor: keyNumber.color?.hex }}>
            {keyNumber.value}
          </div>
          <div className={styles.label}>{keyNumber.label}</div>
          <div className={styles.description}>{keyNumber.description}</div>
        </li>
      )
    })
  }

  return (
    <div
      className={getKeyNumbersStyle()}
      style={
        data.__typename === "DatoCmsKeyNumberColoredBackground" ? { backgroundColor: data.backgroundColor?.hex } : null
      }
    >
      <h3 className={styles.title}>{data.title}</h3>
      <ul className={styles.keyNumberList}>{buildKeyNumbers()}</ul>
    </div>
  )
}

export const fragments = graphql`
  fragment KeyNumberSimpleFields on DatoCmsKeyNumberSimple {
    __typename
    id
    title
    keyNumberList {
      id
      value
      label
      description
    }
  }

  fragment KeyNumberColoredFields on DatoCmsKeyNumberColored {
    __typename
    id
    title
    keyNumberList {
      id
      color {
        hex
      }
      value
      label
      description
    }
  }

  fragment KeyNumberColoredBackgroundFields on DatoCmsKeyNumberColoredBackground {
    __typename
    id
    title
    backgroundColor {
      hex
    }
    keyNumberList {
      id
      value
      label
      description
    }
  }
`

export default KeyNumbers
