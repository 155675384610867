import React from "react"
import { ReactSVG } from "react-svg"

type RenderProps = {
  image: FileField
  svgStyle?: string
  imgStyle?: string
  pictogramColor?: string
  fillPictogramColor?: string
  isLogo?: boolean
  heightSize?: number
}

export const DisplayImage: React.FC<RenderProps> = ({
  image,
  svgStyle,
  imgStyle,
  pictogramColor,
  fillPictogramColor,
  isLogo,
  heightSize,
}) => {
  return image.format === "svg" ? (
    <ReactSVG
      src={image.url}
      height={heightSize || "none"}
      className={svgStyle}
      aria-hidden={true}
      fill={fillPictogramColor || "none"}
      beforeInjection={svg => {
        svg.setAttribute("alt", image.alt || "")
        if (pictogramColor) {
          svg.querySelector("path").setAttribute("fill", pictogramColor)
        }
        if (isLogo) {
          svg.setAttribute("objectFit", "contain")
        }
      }}
    />
  ) : (
    <img src={image.url} alt={image.alt || ""} className={imgStyle} aria-hidden={true} />
  )
}
