import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { RelatedContentInternalFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./internal-block.module.scss"
import sanitizeHtml from "sanitize-html"
import UserJourneyButtonArrow from "../../../../../../static/vectors/user_journey_button_arrow.svg"
import { useLocale } from "../../../../context/locale-context"
import { DisplayImage } from "../../../display-image/display-image"

type RenderProps = {
  data: RelatedContentInternalFieldsFragment
}

const InternalBlock: React.FC<RenderProps> = ({ data }) => {
  const { locale, defaultLocale } = useLocale()
  const url = locale === defaultLocale ? `/${data.internalPage.slug}` : `/${locale}/${data.internalPage.slug}`
  const chapo =
    data.internalPage.chapo.length < 200 ? data.internalPage.chapo : `${data.internalPage.chapo.substring(0, 200)}...`

  return (
    <a href={url} target="_blank" className={styles.link}>
      <div className={styles.card}>
        <DisplayImage image={data.internalPage.image} svgStyle={styles.pictogram} imgStyle={styles.picture} />
        <div className={styles.cardContent}>
          {data.tag && <div className={styles.tag}>{data.tag}</div>}
          <div className={styles.title}>{data.internalPage.title}</div>
          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(chapo) }} className={styles.description} />
          <div className={styles.cardFooter}>
            <ReactSVG src={UserJourneyButtonArrow} className={styles.arrow} aria-hidden={true} />
          </div>
        </div>
      </div>
    </a>
  )
}

export const fragments = graphql`
  fragment RelatedContentInternalFields on DatoCmsRelatedContentInternal {
    tag
    internalPage {
      ... on DatoCmsPage {
        title
        slug
        chapo
        image {
          alt
          format
          url
        }
      }
      ... on DatoCmsParentPage {
        title
        slug
        chapo
        image {
          alt
          format
          url
        }
      }
      ... on DatoCmsNews {
        title
        slug
        chapo
        image {
          alt
          format
          url
        }
      }
      ... on DatoCmsPressRelease {
        title
        slug
        chapo
        image {
          alt
          format
          url
        }
      }
    }
  }
`

export default InternalBlock
