import React, { useRef } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { BiographicCardVerticalFieldsFragment, V3SocialNetworkFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./v3-biographic-card-vertical.module.scss"
import { useLocale } from "../../../../context/locale-context"
import { useSlugs } from "../../../../context/slugs-context"

type RenderProps = {
  data: BiographicCardVerticalFieldsFragment
  governancePageSlug?: string
  socialNetworks: V3SocialNetworkFieldsFragment[]
}
const displayIcon = (link: string, icon: string, style: string) => {
  return (
    <a href={link} className={style}>
      <ReactSVG src={icon} aria-hidden={true} />
    </a>
  )
}
const BiographicCardVertical: React.FC<RenderProps> = ({ data, socialNetworks }) => {
  const { governanceMembersPageSlug } = useSlugs()
  const { locale, defaultLocale } = useLocale()
  const refContainer = useRef(null)

  const getSlugByLocale = (slug: string) =>
    locale === defaultLocale
      ? `/${governanceMembersPageSlug}/${slug}`
      : `/${locale}/${governanceMembersPageSlug}/${slug}`

  return (
    <nav className={styles.navigation} ref={refContainer} role="navigation">
      <ul className={styles.cardsContainer}>
        {data.members?.map(member => {
          return (
            <li key={member.id} className={styles.card}>
              {member.dedicatedPage && <a href={getSlugByLocale(member.slug)} className={styles.allCardLink} />}
              <img className={styles.memberPhoto} src={member.photo.url} alt={member.photo.alt} />
              <div className={styles.filter}
               style={{"--filter-color": member.opacifyingFilter?.at(0)?.filterColor?.hex ?? "white",
                      "--filter-opacity": (member.opacifyingFilter.at(0)?.filterOpacity ?? 10)/ 100} as React.CSSProperties}
              />
              <div className={styles.name}>{member.name}</div>

              <div className={styles.bioContainer}>
                <span>{member.function}</span>
                <div className={styles.socialNetworkContainer}>
                  {member.linkedinProfile &&
                    displayIcon(
                      member.linkedinProfile,
                      socialNetworks.find(network => network.link.includes("linkedin"))?.image.url,
                      styles.linkLinkedin
                    )}
                  {member.twitterProfile &&
                    displayIcon(
                      member.twitterProfile,
                      socialNetworks.find(network => network.link.includes("twitter"))?.image.url,
                      styles.linkTwitter
                    )}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export const fragment = graphql`
  fragment BiographicCardVerticalFields on DatoCmsBiographicCardVertical {
    members {
      id
      name
      function
      linkedinProfile
      twitterProfile
      photo {
        alt
        format
        title
        url
      }
      opacifyingFilter {
        filterColor {
          hex
        }
        filterOpacity
      }
      dedicatedPage
      slug
    }
  }
`

export default BiographicCardVertical
