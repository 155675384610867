// extracted by mini-css-extract-plugin
export var background = "recruitment-module--background--77471";
export var capsule = "recruitment-module--capsule--88aab";
export var carouselContainer = "recruitment-module--carouselContainer--67ccd";
export var imagesContainer = "recruitment-module--imagesContainer--5de7c";
export var mainImage = "recruitment-module--mainImage--5831e";
export var mainInformations = "recruitment-module--mainInformations--5aa1d";
export var rightContainer = "recruitment-module--rightContainer--2e65b";
export var text = "recruitment-module--text--0c93d";
export var title = "recruitment-module--title--d00f9";
export var titleContainer = "recruitment-module--titleContainer--0e29d";