// extracted by mini-css-extract-plugin
export var arrow = "external-block-module--arrow--9cf56";
export var card = "external-block-module--card--75e0b";
export var cardContent = "external-block-module--cardContent--99f7d";
export var cardFooter = "external-block-module--cardFooter--1cda7";
export var description = "external-block-module--description--be306";
export var link = "external-block-module--link--ba855";
export var pictogram = "external-block-module--pictogram--c010f";
export var picture = "external-block-module--picture--f2533";
export var tag = "external-block-module--tag--dcde5";
export var title = "external-block-module--title--6b86b";