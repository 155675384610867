import React from "react"
import { graphql } from "gatsby"
import { V3VideoFieldsFragment } from "../../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import * as styles from "./v3-video.module.scss"

type RenderProps = {
  data: V3VideoFieldsFragment
}

const V3Video: React.FC<RenderProps> = ({ data }) => {
  const sanitizedText = sanitizeHtml(data.heading)

  return (
    <div className={styles.videoContainer}>
      <div className={styles.heading}>
        <p className={styles.text} dangerouslySetInnerHTML={{ __html: sanitizedText }} />
      </div>
      <iframe
        className={styles.iframe}
        src={data.url}
        title="Video player"
        tabIndex={-1}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation allow-modals allow-top-navigation"
        loading="lazy"
        allowFullScreen
      />
    </div>
  )
}

export const fragment = graphql`
  fragment V3VideoFields on DatoCmsV3Video {
    __typename
    url
    heading
  }
`

export default V3Video
