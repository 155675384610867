import React from "react"
import { graphql } from "gatsby"
import { SiteMapColumnsFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./site-map-columns.module.scss"
import { useSlugs } from "../../../../context/slugs-context"

type RenderProps = {
  columns: SiteMapColumnsFieldsFragment[]
}

const SiteMapColumns: React.FC<RenderProps> = ({ columns }) => {
  const { getInternalExternalLink } = useSlugs()

  return columns.map((column, index) => (
    <div className={styles.column} key={index}>
      <span className={styles.title}>{column.title}</span>
      <ul>
        {column.redirectLinks.map(redirectLink => (
          <li key={redirectLink.id}>
            <a
              href={getInternalExternalLink(redirectLink)}
              target={redirectLink.isInternalPage ? "_self" : "_blank"}
              className={styles.redirectLink}
            >
              {redirectLink.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ))
}

export const fragment = graphql`
  fragment SiteMapColumnsFields on DatoCmsV3FooterSiteMapColumn {
    title
    redirectLinks {
      ...InternalExternalLinkBlockFields
    }
  }
`

export default SiteMapColumns
