import React from "react"
import { graphql } from "gatsby"
import { InternalMeshFieldsFragment } from "../../../../graphql-types"
import * as styles from "./internal-mesh.module.scss"
import ScrollArrow from "../tools/scroll-arrow/scroll-arrow"
import { useSlugs } from "../../context/slugs-context"

type RenderProps = {
  data: InternalMeshFieldsFragment & {
    previousPage?: {
      subtitle?: string
    }
    nextPage?: {
      subtitle?: string
    }
  }
}

const InternalMesh: React.FC<RenderProps> = ({ data }) => {
  const { buildInternalLink } = useSlugs()

  return (
    <div className={styles.internalMesh}>
      {data.previousPage && (
        <a href={buildInternalLink(data.previousPage)} className={styles.previousPage}>
          <ScrollArrow direction="left" />
          <div className={styles.textContainer}>
            <span className={`${styles.text} ${data.previousPage.subtitle ? styles.bold : ""}`}>
              {data.previousPage.title}
            </span>
            {data.previousPage.subtitle && <span className={styles.text}>{data.previousPage.subtitle}</span>}
          </div>
        </a>
      )}
      {data.nextPage && (
        <a href={buildInternalLink(data.nextPage)} className={styles.nextPage}>
          <div className={styles.textContainer}>
            <span className={`${styles.text} ${data.nextPage.subtitle ? styles.bold : ""}`}>{data.nextPage.title}</span>
            {data.nextPage.subtitle && <span className={styles.text}>{data.nextPage.subtitle}</span>}
          </div>
          <ScrollArrow direction="right" />
        </a>
      )}
    </div>
  )
}

export const fragment = graphql`
  fragment InternalMeshFields on DatoCmsInternalMesh {
    previousPage {
      __typename
      ... on DatoCmsPage {
        title
        slug
      }
      ... on DatoCmsNews {
        title
        slug
      }
      ... on DatoCmsPressRelease {
        title
        slug
      }
      ... on DatoCmsParentPage {
        title
        slug
      }
      ... on DatoCmsThematicPulse {
        title
        slug
      }
      ... on DatoCmsTransportNetwork {
        title: networkName
        slug
      }
      ... on DatoCmsGovernanceMember {
        title: name
        slug
      }
    }
    previousPageTitle
    nextPage {
      __typename
      ... on DatoCmsPage {
        title
        slug
      }
      ... on DatoCmsNews {
        title
        slug
      }
      ... on DatoCmsPressRelease {
        title
        slug
      }
      ... on DatoCmsParentPage {
        title
        slug
      }
      ... on DatoCmsThematicPulse {
        title
        slug
      }
      ... on DatoCmsTransportNetwork {
        title: networkName
        slug
      }
      ... on DatoCmsGovernanceMember {
        title: name
        slug
      }
    }
    nextPageTitle
  }
`

export default InternalMesh
