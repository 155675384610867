import React from "react"
import { graphql } from "gatsby"
import { VideoFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./video.module.scss"

type RenderProps = {
  data: VideoFieldsFragment
}

const Video: React.FC<RenderProps> = ({ data }) => {
  return (
    <div className={styles.iframeContainer}>
      <iframe
        className={styles.iframe}
        src={data.url}
        title={data.title?.length > 0 ? data.title : "Video player"}
        tabIndex={-1}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation allow-modals allow-top-navigation"
        loading="lazy"
        allowFullScreen
      />
    </div>
  )
}

export const fragments = graphql`
  fragment VideoFields on DatoCmsVideo {
    __typename
    id
    title
    url
  }
`

export default Video
