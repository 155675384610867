import React from "react"
import { graphql } from "gatsby"
import { NoSearchResultsFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./no-search-results.module.scss"
import RichDatoCmsContent from "../../../components/rich-dato-cms-content/rich_dato_cms_content"
import { DisplayImage } from "../../../components/display-image/display-image"
import FormInput from "../form-input/form-input"

type RenderProps = {
  data: NoSearchResultsFieldsFragment
  searchTerms: string
  onChange: (value: string) => void
}

const NoSearchResults: React.FC<RenderProps> = ({ data, searchTerms, onChange }) => (
  <div className={styles.content}>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{`${data.resultsNotFoundTitle} "${searchTerms}" !`}</h1>
      <div className={styles.structuredText}>
        <RichDatoCmsContent data={data.resultsNotFoundText} />
      </div>

      <FormInput searchTerms={searchTerms} placeholder={data.placeholder} onChange={onChange} />
    </div>

    <DisplayImage image={data.resultsNotFoundImage} imgStyle={styles.photo} />
  </div>
)

export const fragment = graphql`
  fragment NoSearchResultsFields on DatoCmsHeader {
    placeholder
    resultsPageTitle
    resultsNotFoundTitle
    resultsNotFoundImage {
      alt
      format
      title
      url
    }
    resultsNotFoundText {
      __typename
      ... on DatoCmsStructuredText {
        structuredText {
          value
        }
      }
    }
    searchInProgressMessage
    errorMessage
    resultsFor
  }
`

export default NoSearchResults
