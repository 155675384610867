import React from "react"
import { ReactSVG } from "react-svg"
import * as config from "../../../../../../config"
import * as styles from "./language-selector.module.scss"
import { useLocale } from "../../../../context/locale-context"
import { useSlugs } from "../../../../context/slugs-context"
import { useChangeLanguage, useFormatLocale } from "../../../../hooks"
import RightArrow from "../../../../../../static/vectors/v3/right-arrow.svg"

type RenderProps = {
  currentRoute: string
  allSlugLocales: { locale: string; value: string }[]
  displayOnTop?: boolean
}

const LanguageSelector: React.FC<RenderProps> = ({ currentRoute, allSlugLocales, displayOnTop = false }) => {
  const { locale, defaultLocale } = useLocale()
  const { slugs } = useSlugs()

  const otherLocales: string[] = config.locales
    .map((otherLocale: string) => useFormatLocale(otherLocale))
    .filter((otherLocale: string) => otherLocale !== locale)

  return (
    <div className={`${styles.languageSelector} ${displayOnTop ? styles.displayOnTop : ""}`}>
      <button className={styles.menuButton}>
        <span className={styles.countryLabel}>{locale}</span>
        <div className={styles.arrow}>
          <ReactSVG src={RightArrow} />
        </div>
      </button>
      <div className={styles.dropdownContent}>
        {otherLocales.map((otherLocale: string) => {
          const localeLink = useChangeLanguage(slugs, currentRoute, allSlugLocales, otherLocale, defaultLocale)
          return (
            <a href={localeLink} key={otherLocale} className={styles.submenuButton}>
              <span className={styles.countryLabel}>{otherLocale}</span>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default LanguageSelector
