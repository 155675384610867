import { format } from "date-fns"
import { enGB, fr } from "date-fns/locale"
import {
  NewsMediaFieldsFragment,
  PressReleaseMediaFieldsFragment,
  PublicationMediaFieldsFragment,
} from "../../../graphql-types"
import { useIsDateInRange } from "./use-is-date-in-range"

export const useSortArticlesByMonth = <
  T extends NewsMediaFieldsFragment | PressReleaseMediaFieldsFragment | PublicationMediaFieldsFragment,
>(
  articles: T[],
  locale: string
) => {
  type ArticleSection = { publicationDate: string; articles: T[] }
  let articlesSections: ArticleSection[] = []
  let articlesByDate: T[] = []
  let lastFormattedDate: string = ""

  if (articles) {
    for (let i = 0; i < articles.length; ++i) {
      const currentArticle: T = articles[i]
      if (currentArticle && useIsDateInRange(currentArticle.publicationDate, currentArticle.publicationEndDate)) {
        let date = format(new Date(currentArticle.publicationDate), "MMMM yyyy", {
          locale: locale === "fr" ? fr : enGB,
        })
        date = date.charAt(0).toUpperCase() + date.slice(1)

        if (lastFormattedDate === "" || date === lastFormattedDate) {
          articlesByDate.push(currentArticle)
        } else if (date != lastFormattedDate) {
          articlesSections.push({ publicationDate: lastFormattedDate, articles: articlesByDate })
          articlesByDate = [currentArticle]
        }
        lastFormattedDate = date
      }
    }
    if (articlesByDate.length > 0) {
      articlesSections.push({ publicationDate: lastFormattedDate, articles: articlesByDate })
    }
  }
  return articlesSections
}
