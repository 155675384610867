import React, { useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { SliderFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./slider.module.scss"
import leftArrow from "../../../../../static/vectors/map_left_arrow.svg"
import rightArrow from "../../../../../static/vectors/map_right_arrow.svg"

type RenderProps = {
  data: SliderFieldsFragment
}

const Slider: React.FC<RenderProps> = ({ data }) => {
  const images = data && data.images ? data.images : []

  const [slideIndex, setSlideIndex] = useState(1)
  const [translationX, setTranslationX] = useState(0)

  /**
   * Move the slider to the previous picture
   */
  const previousSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1)
      setTranslationX(translationX + 100)
    } else if (slideIndex === 1) {
      setSlideIndex(images.length)
      setTranslationX(-(images.length - 1) * 100)
    }
  }

  /**
   * Move the slider to the next picture
   */
  const nextSlide = () => {
    if (slideIndex !== images.length) {
      setSlideIndex(slideIndex + 1)
      setTranslationX(translationX - 100)
    } else {
      setSlideIndex(1)
      setTranslationX(0)
    }
  }

  /**
   * Move the slider to the selected picture
   */
  const moveSlide = (position: number) => {
    setTranslationX(position * -100)
    setSlideIndex(position + 1)
  }

  return (
    <div className={styles.container} aria-hidden={true}>
      {images.map((img, index) => {
        return (
          <img
            className={styles.img}
            style={{
              transform: "translateX(" + translationX + "%)",
            }}
            src={img.url}
            alt={img.title || ""}
            loading="eager"
            key={index}
          />
        )
      })}
      <div className={styles.overImageLayer}>
        <div className={styles.leftChevron} aria-label="Précédent">
          <ReactSVG src={leftArrow} className={styles.svgContainer} onClick={previousSlide} aria-hidden={true} />
        </div>
        <div className={styles.rightChevron} aria-label="Suivant">
          <ReactSVG src={rightArrow} className={styles.svgContainer} onClick={nextSlide} aria-hidden={true} />
        </div>
        <div className={styles.bottomNavigator}>
          {images.map((img, index) => {
            return (
              <button
                className={slideIndex === index + 1 ? styles.radioButtonActive : styles.radioButton}
                onClick={() => moveSlide(index)}
                aria-label={`Image ${index + 1}`}
                key={index}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment SliderFields on DatoCmsSlider {
    __typename
    id
    images {
      title
      url
      height
      width
    }
  }
`

export default Slider
