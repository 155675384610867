import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, GovernanceMemberDetailsQuery } from "../../../../graphql-types"
import * as styles from "./governance-member-details.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue } from "../../hooks"
import Layout from "../../components/layout/layout"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich_dato_cms_content"
import { DisplayImage } from "../../components/display-image/display-image"
import LinkedinGray from "../../../../static/images/linkedin_gray.svg"
import TwitterGray from "../../../../static/images/twitter_gray.svg"
import InternalMesh from "../../components/internal-mesh/internal-mesh"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: GovernanceMemberDetailsQuery
  location: Location
  pageContext: {
    id: string
    previousMember: { name: string; slug: string; job: string }
    nextMember: { name: string; slug: string; job: string }
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
    allSlugLocales: { locale: string; value: string }[]
  }
}

const GovernanceMemberDetails: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const member = data.datoCmsGovernanceMember
  const { linkedinProfile: linkedin, twitterProfile: twitter } = member
  const internalMeshData = {
    previousPage: {
      __typename: "DatoCmsGovernanceMember" as const,
      title: pageContext.previousMember.name,
      subtitle: pageContext.previousMember.job,
      slug: pageContext.previousMember.slug,
    },
    nextPage: {
      __typename: "DatoCmsGovernanceMember" as const,
      title: pageContext.nextMember.name,
      subtitle: pageContext.nextMember.job,
      slug: pageContext.nextMember.slug,
    },
  }

  const buildSocialNetworks = () => {
    if (!(twitter?.length > 0 || linkedin?.length > 0)) return <></>
    return (
      <div className={styles.socialNetworkContainer}>
        <span>{`${data.datoCmsGovernance.followMemberText1} ${data.datoCmsGovernance.followMemberText2}: `}</span>
        <ul>
          <li>
            <a href={linkedin} aria-label="LinkedIn">
              <ReactSVG src={LinkedinGray} />
            </a>
          </li>
          <li>
            <a href={twitter} aria-label="Twitter">
              <ReactSVG src={TwitterGray} />
            </a>
          </li>
        </ul>
      </div>
    )
  }

  const mobileRendering = () => {
    return (
      <section className={styles.container}>
        <div className={styles.informations}>
          <DisplayImage image={member.photo} imgStyle={styles.photo} svgStyle={styles.photo} />
          <h1 className={styles.name}>{member.name}</h1>
        </div>
        <div className={styles.content}>
          <h3 className={styles.job}>{member.function}</h3>
          <RichDatoCmsContent data={member.richcontent} />
          {buildSocialNetworks()}
          <div className={styles.internalMeshWrapper}>
            <InternalMesh data={internalMeshData} />
          </div>
        </div>
      </section>
    )
  }

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allGovernanceMembersPageSlugLocales, pageContext.locale)}
      pageTemplate="governance member"
      allSlugLocales={pageContext.allSlugLocales}
    >
      {isMobile ? (
        mobileRendering()
      ) : (
        <section className={styles.container}>
          <Breadcrumb
            title={member.name}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={data.datoCmsGovernance.middlePages}
          />
          <div className={styles.content}>
            <div className={styles.member}>
              <h1 className={styles.name}>{member.name}</h1>
              <h3 className={styles.job}>{member.function}</h3>
              <RichDatoCmsContent data={member.richcontent} />
            </div>
            <div className={styles.informations}>
              <DisplayImage image={member.photo} imgStyle={styles.photo} svgStyle={styles.photo} />
              {buildSocialNetworks()}
            </div>
          </div>
          <InternalMesh data={internalMeshData} />
        </section>
      )}
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => {
  const currentMember = data.datoCmsGovernance.members.find(m => m.id === pageContext.id)

  return (
    <>
      <SEO
        title={currentMember?.titleTag}
        description={currentMember?.metaDescription}
        noindex={currentMember?.noindex}
        locale={useFormatLocale(pageContext.locale)}
      />
      {typeof window !== "undefined" && (
        <HrefLangLinks
          defaultDomain={window.location.origin}
          defaultLocale={pageContext.defaultLocale}
          slugs={[pageContext.slugs._allGovernanceMembersPageSlugLocales, pageContext.allSlugLocales]}
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query GovernanceMemberDetails($locale: String!, $id: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsGovernance(locale: $locale) {
      members {
        id
        titleTag
        metaDescription
        noindex
      }
      middlePages {
        ...InternalExternalLinkBlockFields
      }
      followMemberText1
      followMemberText2
    }

    datoCmsGovernanceMember(locale: $locale, id: { eq: $id }) {
      name
      function
      photo {
        alt
        url
        format
      }
      linkedinProfile
      twitterProfile
      richcontent {
        ...RichContentFields
      }
    }
  }
`

export default GovernanceMemberDetails
