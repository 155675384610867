import React from "react"
import * as styles from "./capsule.module.scss"

type RenderProps = {
  title: string
  isSelected: boolean
  special?: boolean
  className?: string
  onClick?: () => void
  link?: {
    url: string
    isInternal: boolean
  }
}

const Capsule: React.FC<RenderProps> = ({ title, isSelected, special = false, className = "", onClick, link }) => {
  const capsuleClassName = `${styles.capsule} ${isSelected ? styles.selected : ""} ${
    special ? styles.special : ""
  } ${className}`

  if (link?.url) {
    return (
      <a href={link.url} target={link.isInternal ? "_self" : "_blank"} className={capsuleClassName} onClick={onClick}>
        {title}
      </a>
    )
  }

  return (
    <button className={capsuleClassName} onClick={onClick}>
      {title}
    </button>
  )
}

export default Capsule
