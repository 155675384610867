import React, { useState, useEffect, useRef } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { DatoCmsFileField, V3HeaderFieldsFragment } from "../../../../../graphql-types"
import * as config from "../../../../../config"
import * as styles from "./header.module.scss"
import { useLocale } from "../../../context/locale-context"
import { useSlugs } from "../../../context/slugs-context"
import { useIsMobile } from "../../../hooks"
import Menu from "./menu/menu"
import Capsule from "./capsule/capsule"
import LanguageSelector from "./language-selector/language-selector"
import BurgerMenu from "./burger-menu/burger-menu"
import LogoMobile from "../../../../../static/vectors/v3/keolis-logo-mobile.svg"
import Globe from "../../../../../static/vectors/v3/globe.svg"
import Search from "../../../../../static/vectors/v3/search.svg"

type RenderProps = {
  data: V3HeaderFieldsFragment
  subsidiaryLogo: DatoCmsFileField
  currentRoute: string
  allSlugLocales: { locale: string; value: string }[]
}

const Header: React.FC<RenderProps> = ({ data, subsidiaryLogo, currentRoute, allSlugLocales }) => {
  const { baseSlug } = useLocale()
  const { mapPageSlug, searchPageSlug } = useSlugs()
  const hasSeveralLanguages = config.locales.length > 1
  const isMedium = useIsMobile(1200)
  const [openedMenu, setOpenedMenu] = useState("")
  const menuRef = useRef<HTMLDivElement>(null)

  const handleSubmenuClick = (e: React.MouseEvent<HTMLAnchorElement>, menuId: string) => {
    if (openedMenu === menuId) {
      setOpenedMenu("")
    } else {
      setOpenedMenu(menuId)
    }
    e.preventDefault()
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenedMenu("")
      }
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <header className={styles.header} data-datocms-noindex={true} role="banner">
      <div className={styles.logo}>
        <a href="/">
          {!isMedium ? (
            <img src={subsidiaryLogo.url} alt={subsidiaryLogo.alt} />
          ) : (
            <ReactSVG src={LogoMobile} aria-hidden={true} />
          )}
        </a>
      </div>
      {!isMedium && (
        <nav className={styles.navMenu} role="navigation" ref={menuRef}>
          <ul className={styles.menus}>
            {data.menus.map(menu => (
              <Menu menu={menu} onClick={handleSubmenuClick} isMenuOpened={openedMenu === menu.id} key={menu.id} />
            ))}
          </ul>
        </nav>
      )}
      <div className={styles.buttons}>
        {!isMedium && data.capsules.length > 0 && (
          <div className={styles.capsules}>
            {data.capsules.map(capsule => (
              <Capsule capsule={capsule} key={capsule.id} />
            ))}
          </div>
        )}
        {data.isMapIconDisplayed && (
          <a href={`${baseSlug}${mapPageSlug}`} className={styles.icon}>
            <ReactSVG src={Globe} className={styles.globeIcon} />
          </a>
        )}
        <a href={`${baseSlug}${searchPageSlug}`} className={styles.icon}>
          <ReactSVG src={Search} className={styles.searchIcon} />
        </a>
        {!isMedium ? (
          hasSeveralLanguages && <LanguageSelector currentRoute={currentRoute} allSlugLocales={allSlugLocales} />
        ) : (
          <BurgerMenu menus={data.menus} currentRoute={currentRoute} allSlugLocales={allSlugLocales} />
        )}
      </div>
    </header>
  )
}

export const enableDidomi = () => {
  const head = document.getElementsByTagName("head")
  const script = document.createElement("script")

  script.type = "text/javascript"
  script.text =
    `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}\n` +
    `else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}\n` +
    `var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}\n` +
    `if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){\n` +
    `var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("${config.didomi_api_key}")})();`

  head[0].prepend(script)
}

export const enableGoogleAnalytics = () => (
  <script
    type="didomi/javascript"
    data-vendor="c:googleana-4TXnJigR"
    dangerouslySetInnerHTML={{
      __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            ga('create', '${config.google_analytics_key}', 'auto');
            ga('send', 'pageview');`,
    }}
  />
)

export const enableGoogleTagManagerHead = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.google_tag_manager_id}');`,
    }}
  />
)

export const enableGoogleTagManagerBody = () => {
  const body = document.getElementsByTagName("body")
  const noscript = document.createElement("noscript")
  const iframe = document.createElement("iframe")

  iframe.src = `https://www.googletagmanager.com/ns.html?id=${config.google_tag_manager_id}`
  iframe.height = "0"
  iframe.width = "0"
  iframe.setAttribute("style", "display:none;visibility:hidden")

  noscript.appendChild(iframe)

  body[0].prepend(noscript)
}

export const fragment = graphql`
  fragment V3HeaderFields on DatoCmsV3Header {
    menus {
      ...V3MenuHeaderFields
    }
    capsules {
      ...V3CapsuleHeaderFields
    }
    isMapIconDisplayed
    searchLabel
  }
`

export default Header
