/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as config from "../../../../config"
import { enableGoogleAnalytics, enableGoogleTagManagerHead } from "../layout/header/header"

const SEO = ({ title, description, locale, noindex }) => {
  const { datoCmsSite } = useStaticQuery(graphql`
    query {
      datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          fallbackSeo {
            description
            title
          }
          siteName
        }
      }
    }
  `)
  const metaDescription = description || datoCmsSite.globalSeo.fallbackSeo.description
  const defaultTitle = datoCmsSite.globalSeo.siteName || datoCmsSite.globalSeo.fallbackSeo.title
  const formattedTitle = defaultTitle ? `${config.site_name} | ${defaultTitle}` : config.site_name
  const titleDisplayed = title || formattedTitle
  const isRtl = locale.endsWith("-ar")

  return (
    <>
      {isRtl ? <html lang={locale} dir={"rtl"} /> : <html lang={locale} />}
      <title>{titleDisplayed}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={titleDisplayed} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="twitter" />
      <meta name="twitter:title" content={titleDisplayed} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="robots" content={noindex ? "noindex" : "index"} />

      {datoCmsSite?.faviconMetaTags?.tags?.map((tags, index) => {
        return tags.tagName === "link" ? (
          <link
            key={index}
            rel={tags.attributes.rel || ""}
            sizes={tags.attributes.sizes || ""}
            type={tags.attributes.type || ""}
            href={tags.attributes.href || ""}
          />
        ) : (
          <meta key={index} name={tags.attributes.name || ""} content={tags.attributes.content || ""} />
        )
      })}

      {config.google_analytics_key && enableGoogleAnalytics()}
      {config.google_tag_manager_id && enableGoogleTagManagerHead()}
    </>
  )
}

export default SEO
