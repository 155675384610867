import React from "react"
import ReactHtmlParser from "react-html-parser"
import { graphql } from "gatsby"
import { V3StructuredTextFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./v3-structured-text-content.module.scss"
import { render } from "datocms-structured-text-to-html-string"
import sanitizeHtml from "sanitize-html"

type RenderProps = {
  data: V3StructuredTextFieldsFragment
}

const StructuredTextContent: React.FC<RenderProps> = ({ data }) => {
  const options = {
    renderBlock({ record, adapter: { renderNode } }) {
      return renderNode("figure", {}, renderNode("img", { src: record.url }))
    },
    renderInlineRecord({ record, adapter: { renderNode } }) {
      return renderNode("a", { href: `/${record.slug}` }, record.title)
    },
    renderLinkToRecord({ record, children, adapter: { renderNode } }) {
      return renderNode("a", { href: `/${record.slug}` }, children)
    },
  }

  return (
    <div className={styles.textBlock}>
      <div className={styles.heading}>
        {data.headingH2 !== null && data.headingH2 !== "" && <h2>{data.headingH2}</h2>}
      </div>
      <div className={styles.structuredText}>
        {data &&
          data.structuredText &&
          ReactHtmlParser(
            sanitizeHtml(render(data.structuredText.value, options), {
              allowedAttributes: {
                ...sanitizeHtml.defaults.allowedAttributes,
                a: (sanitizeHtml.defaults.allowedAttributes.a || []).concat(["href"]),
              },
              allowedSchemesByTag: {
                ...sanitizeHtml.defaults.allowedSchemesByTag,
                a: ["http", "https", "javascript", "mailto"],
              },
              exclusiveFilter: frame =>
                frame.tag === "a" &&
                frame.attribs.href &&
                frame.attribs.href.startsWith("javascript") &&
                !frame.attribs.href.startsWith("javascript:Didomi"),
            })
          )}
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment V3StructuredTextFields on DatoCmsV3StructuredText {
    __typename
    id
    headingH2
    structuredText {
      value
    }
  }
`

export default StructuredTextContent
