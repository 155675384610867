import React, { useState, useEffect } from "react"
import { RegionNetworksFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./geographic-filters.module.scss"
import localisation from "../../../../../static/vectors/v3/localisation.svg"

type RenderProps = {
  allTransportNetworks: RegionNetworksFieldsFragment[]
  onNetworkClick: (focus, network?) => void
}

// Subcomponent
const Networks = ({ networks, onNetworkClick }) => {
  return (
    <ul className={styles.networks}>
      {networks.map((network, networkIndex) => (
        <li
          key={networkIndex}
          className={styles.network}
          onClick={() =>
            onNetworkClick(
              { zoom: 8, lat: network.networkLocation.latitude, lng: network.networkLocation.longitude },
              network
            )
          }
        >
          <img src={localisation} className={styles.localisation} alt="localisation" />
          {network.networkName}
        </li>
      ))}
    </ul>
  )
}

// Subcomponent
const Subzones = ({ subZones, mainZoneIndex, onNetworkClick }) => {
  const [openSubZones, setOpenSubZones] = useState([])

  const toggleZone = (index, state, setState) => {
    setState(prevState => {
      if (prevState.includes(index)) {
        return prevState.filter(i => i !== index) // Close if already open
      } else {
        return [...prevState, index] // Open if closed
      }
    })
  }

  return (
    <ul className={styles.subZones}>
      {subZones.map((subZone, subZoneIndex) => (
        <li key={subZoneIndex} className={styles.subZone}>
          <span
            className={styles.subZoneName}
            onClick={() => toggleZone(`${mainZoneIndex}-${subZoneIndex}`, openSubZones, setOpenSubZones)}
          >
            {`${subZone.name} (${subZone.network.length})`}
          </span>
          {openSubZones.includes(`${mainZoneIndex}-${subZoneIndex}`) && (
            <Networks networks={subZone.network} onNetworkClick={onNetworkClick} />
          )}
        </li>
      ))}
    </ul>
  )
}

// Main component
const GeographicFilters: React.FC<RenderProps> = ({ allTransportNetworks, onNetworkClick }) => {
  // Build the geographic zones and subzones without duplicates
  const zones = allTransportNetworks.reduce((acc, curr) => {
    if (!curr.geographicZone) return acc
    const mainZoneName = curr.geographicZone.mainZone.name
    const existingMainZone = acc.find(item => item.mainZone.name === mainZoneName)
    if (!existingMainZone) {
      acc.push({
        mainZone: {
          name: mainZoneName,
          location: curr.geographicZone.mainZone.location,
          networkCount: 1,
        },
        subZones: [
          {
            ...curr.geographicZone,
            network: [curr],
          },
        ],
      })
    } else {
      existingMainZone.mainZone.networkCount += 1
      const subZoneExists = existingMainZone.subZones.some(subZone => subZone.name === curr.geographicZone.name)
      if (!subZoneExists) {
        existingMainZone.subZones.push({
          ...curr.geographicZone,
          network: [curr],
        })
      } else {
        const subZone = existingMainZone.subZones.find(subZone => subZone.name === curr.geographicZone.name)
        subZone.network.push(curr)
      }
    }

    return acc
  }, [])

  const [openMainZones, setOpenMainZones] = useState([])

  const onMainZoneClick = (zone, mainZoneIndex, openMainZones, setOpenMainZones) => {
    toggleZone(mainZoneIndex, openMainZones, setOpenMainZones)
    onNetworkClick({ zoom: 3, lat: zone.mainZone.location.latitude, lng: zone.mainZone.location.longitude })
  }

  const toggleZone = (index, state, setState) => {
    setState(prevState => {
      if (prevState.includes(index)) {
        return prevState.filter(i => i !== index)
      } else {
        return [...prevState, index]
      }
    })
  }

  return (
    <ul className={styles.mainZones}>
      {zones.map((zone, mainZoneIndex) => (
        <li key={mainZoneIndex} className={styles.mainZone}>
          <h3 onClick={() => onMainZoneClick(zone, mainZoneIndex, openMainZones, setOpenMainZones)}>
            <img src={localisation} className={styles.localisation} alt="localisation" />{" "}
            {`${zone.mainZone.name} (${zone.mainZone.networkCount})`}
          </h3>
          {openMainZones.includes(mainZoneIndex) && (
            <Subzones subZones={zone.subZones} mainZoneIndex={mainZoneIndex} onNetworkClick={onNetworkClick} />
          )}
        </li>
      ))}
    </ul>
  )
}

export default GeographicFilters
