import React, { useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { ButtonFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./button.module.scss"
import MapRightArrow from "../../../../../static/vectors/map_right_arrow.svg"
import ExternalLink from "../../../../../static/vectors/external_link.svg"
import { DisplayImage } from "../../display-image/display-image"

type RenderProps = {
  data: ButtonFieldsFragment
}

const Button: React.FC<RenderProps> = ({ data }) => {
  const [hoverButton1, setHoverButton1] = useState(false)
  const [hoverButton2, setHoverButton2] = useState(false)

  const renderButton = (title, pictogram, link, color, color2, isImgButton, imgContent, hover, setHover) => {
    if (isImgButton) {
      return (
        <button
          className={styles.buttonContainerImg}
          style={
            color?.hex && {
              backgroundColor: hover ? color2.hex : color.hex,
              border: hover && `1px solid ${color.hex}`,
            }
          }
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <a
            href={link?.isInternalPage && link?.page ? `/${link.page.slug}` : link?.url}
            className={!imgContent ? styles.buttonContent : styles.buttonContentImg}
            target={link?.isInternalPage ? "_self" : "_blank"}
          >
            {imgContent && <DisplayImage image={imgContent} svgStyle={styles.btnImage} imgStyle={styles.btnImage} />}
          </a>
        </button>
      )
    } else {
      return (
        <button
          className={styles.buttonContainer}
          style={
            color?.hex && {
              backgroundColor: hover ? color2.hex : color.hex,
              border: hover && `1px solid ${color.hex}`,
            }
          }
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <a
            href={link?.isInternalPage && link?.page ? `/${link.page.slug}` : link?.url}
            className={styles.buttonContent}
            target={link?.isInternalPage ? "_self" : "_blank"}
          >
            {pictogram && (
              <DisplayImage
                image={pictogram}
                svgStyle={styles.pictogram}
                imgStyle={styles.image}
                pictogramColor={hover ? color.hex : color2.hex}
              />
            )}
            <div className={styles.textContent}>
              <div className={styles.title} style={{ color: hover ? color?.hex : color2?.hex }}>
                {title}
              </div>
            </div>
            <div className={styles.content}>
              <ReactSVG
                src={link?.isInternalPage ? MapRightArrow : ExternalLink}
                className={styles.arrow}
                aria-hidden={true}
                beforeInjection={svg =>
                  svg.querySelector("path").setAttribute("fill", hover ? color?.hex : color2?.hex)
                }
              />
            </div>
          </a>
        </button>
      )
    }
  }

  return (
    <div className={styles.container}>
      {renderButton(
        data.title,
        data.pictogram,
        data.link,
        data.color,
        data.hoverColor,
        data.isImgButton,
        data.imgContent,
        hoverButton1,
        setHoverButton1
      )}
      {data.button2 &&
        renderButton(
          data.title2,
          data.pictogram2,
          data.link2,
          data.color2,
          data.hoverColor2,
          data.isImgButton2,
          data.imgContent2,
          hoverButton2,
          setHoverButton2
        )}
    </div>
  )
}

export const fragments = graphql`
  fragment ButtonFields on DatoCmsButton {
    __typename
    id
    isImgButton
    imgContent {
      alt
      format
      url
    }
    title
    pictogram {
      alt
      format
      url
    }
    link {
      isInternalPage
      url
      page {
        slug
      }
    }
    color {
      hex
    }
    hoverColor {
      hex
    }
    button2
    isImgButton2
    imgContent2 {
      alt
      format
      url
    }
    title2
    pictogram2 {
      alt
      format
      url
    }
    link2 {
      url
      page {
        slug
      }
      isInternalPage
    }
    color2 {
      hex
    }
    hoverColor2 {
      hex
    }
  }
`

export default Button
