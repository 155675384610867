import React from "react"
import * as styles from "./scroll-arrow.module.scss"

const arrowPath = "/vectors/v3/"
type RenderProps = {
  direction: "left" | "right"
  style?: React.CSSProperties
  onClick?: () => void
}
const ScrollArrow: React.FC<RenderProps> = ({ direction, style, onClick }) => {
  return (
    <div className={styles.arrows} style={style} onClick={onClick}>
      <img
        src={`${arrowPath}left-arrow.svg`}
        className={direction === "right" ? styles.rightArrow : ""}
        alt={direction === "left" ? "left arrow" : "right arrow"}
      />
    </div>
  )
}
export default ScrollArrow
