import React from "react"
import { ReactSVG } from "react-svg"
import * as styles from "./card-split.module.scss"
import { useFormatDate } from "../../../../hooks"
import { useLocale } from "../../../../context/locale-context"

type RenderProps = {
  title: string
  date: string
  backgroundSvg: string
  backgroundImage: {
    url: string
    alt: string
  }
  backgroundColor?: string
  backgroundColorBis?: string
}

const CardSplit: React.FC<RenderProps> = ({
  title,
  date,
  backgroundSvg,
  backgroundImage,
  backgroundColor,
  backgroundColorBis,
}) => {
  const { locale } = useLocale()

  return (
    <div className={styles.card}>
      <div className={styles.imagesContainer}>
        <img className={styles.image} src={backgroundImage.url} alt={backgroundImage.alt} />
        <div className={styles.svgContainer}>
          <ReactSVG
            className={styles.svg}
            src={backgroundSvg}
            afterInjection={svg => {
              svg.querySelector("rect").style.fill = backgroundColor
              const paths = svg.querySelectorAll("path")
              paths.forEach(path => (path.style.fill = backgroundColorBis))
            }}
          />
          <div className={styles.cardContent}>
            <div className={styles.date}>{useFormatDate(date, locale)} </div>
            <div className={styles.title}>
              <span>{title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardSplit
