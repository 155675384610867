import React, { useState } from "react"
import { Turn as Hamburger } from "hamburger-react"
import * as config from "../../../../../../config"
import * as styles from "./burger-menu.module.scss"
import { V3MenuHeaderFieldsFragment } from "../../../../../../graphql-types"
import Accordion from "./accordion/accordion"
import LanguageSelector from "../language-selector/language-selector"

type RenderProps = {
  menus: V3MenuHeaderFieldsFragment[]
  currentRoute: string
  allSlugLocales: { locale: string; value: string }[]
}

const BurgerMenu: React.FC<RenderProps> = ({ menus, currentRoute, allSlugLocales }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const hasSeveralLanguages = config.locales.length > 1

  return (
    <nav className={styles.burgerMenuIcon} role="navigation">
      <div>
        <Hamburger
          label="Burger Menu"
          hideOutline={false}
          color="#20405f"
          size={18}
          toggled={isMenuOpened}
          toggle={setIsMenuOpened}
          distance="md"
          rounded
        />
      </div>
      <div className={`${styles.menus} ${isMenuOpened ? styles.opened : styles.closed}`}>
        <ul>
          {menus.map(menu => (
            <Accordion menu={menu} key={menu.id} />
          ))}
        </ul>
        {hasSeveralLanguages && (
          <div className={styles.languageSelector}>
            <LanguageSelector currentRoute={currentRoute} allSlugLocales={allSlugLocales} displayOnTop={true} />
          </div>
        )}
      </div>
    </nav>
  )
}

export default BurgerMenu
