import React, { createContext, useContext } from "react"
import { useFormatLocale } from "../hooks"

type LocaleProviderProps = {
  children: React.ReactNode
  locale: string
  defaultLocale: string
}

type LocaleContextData = {
  locale: string
  defaultLocale: string
  baseSlug: string
}

const LocaleContext = createContext<LocaleContextData | undefined>(undefined)

export const useLocale = () => {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error("useLocale must be used inside LocaleProvider")
  }

  return context
}

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children, locale, defaultLocale }) => {
  const localeFormatted = useFormatLocale(locale)
  const defaultLocaleFormatted = useFormatLocale(defaultLocale)
  const baseSlug = locale === defaultLocale ? "/" : `/${locale}/`

  return (
    <LocaleContext.Provider value={{ locale: localeFormatted, defaultLocale: defaultLocaleFormatted, baseSlug }}>
      {children}
    </LocaleContext.Provider>
  )
}
