// extracted by mini-css-extract-plugin
export var bioContainer = "biographic-card-horizontal-module--bioContainer--a6ae7";
export var biographicBlock = "biographic-card-horizontal-module--biographicBlock--2e51b";
export var cardsContainer = "biographic-card-horizontal-module--cardsContainer--cb6d9";
export var container = "biographic-card-horizontal-module--container--2ec61";
export var content = "biographic-card-horizontal-module--content--4018b";
export var emptyLeftPart = "biographic-card-horizontal-module--emptyLeftPart--bedc1";
export var job = "biographic-card-horizontal-module--job--65ad3";
export var linkLinkedin = "biographic-card-horizontal-module--linkLinkedin--11d64";
export var linkTwitter = "biographic-card-horizontal-module--linkTwitter--ca855";
export var linksContainer = "biographic-card-horizontal-module--linksContainer--7aa07";
export var name = "biographic-card-horizontal-module--name--5a510";
export var photo = "biographic-card-horizontal-module--photo--b56fe";
export var photoContainer = "biographic-card-horizontal-module--photoContainer--914ff";
export var sliderButton = "biographic-card-horizontal-module--sliderButton--e5970";