// extracted by mini-css-extract-plugin
export var content = "outlined-text-block-module--content--e7bc8";
export var contentText = "outlined-text-block-module--contentText--ce5d5";
export var leftContainer = "outlined-text-block-module--leftContainer--f7cc6";
export var outlinedContainer = "outlined-text-block-module--outlinedContainer--bfd16";
export var picture = "outlined-text-block-module--picture--fc70d";
export var pictureWrapper = "outlined-text-block-module--pictureWrapper--40537";
export var rightContainer = "outlined-text-block-module--rightContainer--c2f60";
export var socialNetworkLink = "outlined-text-block-module--socialNetworkLink--a3c65";
export var socialNetworkLinkWrapper = "outlined-text-block-module--socialNetworkLinkWrapper--62bcc";
export var socialNetworkLogo = "outlined-text-block-module--socialNetworkLogo--e8916";
export var socialNetworks = "outlined-text-block-module--socialNetworks--c103d";
export var title = "outlined-text-block-module--title--f1441";