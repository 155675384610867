// extracted by mini-css-extract-plugin
export var coloredBackgroundFormat = "key-numbers-module--coloredBackgroundFormat--b3864";
export var coloredNumbersFormat = "key-numbers-module--coloredNumbersFormat--e689e";
export var description = "key-numbers-module--description--5e2f6";
export var keyNumber = "key-numbers-module--keyNumber--9bf86";
export var keyNumberList = "key-numbers-module--keyNumberList--f1aa4";
export var keyNumbers = "key-numbers-module--keyNumbers--8f6c2";
export var label = "key-numbers-module--label--0368b";
export var simpleFormat = "key-numbers-module--simpleFormat--60287";
export var title = "key-numbers-module--title--1039f";
export var value = "key-numbers-module--value--8cce6";