import React from "react"
import { graphql } from "gatsby"
import { RelatedContentFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./related-content.module.scss"
import ExternalBlock from "./external-block/external-block"
import InternalBlock from "./internal-block/internal-block"

type RenderProps = {
  data: RelatedContentFieldsFragment
}

const RelatedContent: React.FC<RenderProps> = ({ data }) => {
  return (
    <div style={{ "--main-color": data.sameSubjectBlockLink.colorBlock.hex } as React.CSSProperties}>
      <h3 className={styles.blockTitle}>{(data && data.sameSubjectBlockLink.blockTitle) || "Sur le même sujet"}</h3>
      {data.sameSubjectBlockLink.pages.map((page, index) => {
        if (page.__typename === "DatoCmsRelatedContentInternal") {
          return <InternalBlock data={page} key={index} />
        } else {
          return <ExternalBlock data={page} key={index} />
        }
      })}
    </div>
  )
}

export const fragments = graphql`
  fragment RelatedContentFields on DatoCmsRelatedContent {
    __typename
    id
    sameSubjectBlockLink {
      blockTitle
      pages {
        __typename
        ... on DatoCmsRelatedContentInternal {
          ...RelatedContentInternalFields
        }
        ... on DatoCmsRelatedContentExternal {
          ...RelatedContentExternalFields
        }
        ... on DatoCmsRelatedContentPdf {
          ...RelatedContentPdfFields
        }
      }
      colorBlock {
        hex
      }
    }
  }
`

export default RelatedContent
