import React, { useState } from "react"
import { ReactSVG } from "react-svg"
import * as styles from "./form-input.module.scss"
import magnifyingGlass from "../../../../../static/vectors/magnifying_glass.svg"

type RenderProps = {
  searchTerms: string
  placeholder: string
  onChange: (value: string) => void
  centered?: boolean
}

const FormInput: React.FC<RenderProps> = ({ searchTerms, placeholder, onChange, centered }) => {
  const [value, setValue] = useState(searchTerms)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    onChange(value)
    event.preventDefault()
  }

  return (
    <form
      method="get"
      role="search"
      onSubmit={handleSubmit}
      className={styles.searchForm}
      style={centered && { margin: "32px auto" }}
    >
      <input type="text" name="query" value={value} onChange={e => setValue(e.target.value)} aria-label={placeholder} />
      <button type="submit" className={styles.searchButton} value="ok" aria-label={placeholder}>
        <ReactSVG src={magnifyingGlass} className={styles.icon} aria-hidden={true} />
      </button>
    </form>
  )
}

export default FormInput
