import React from "react"
import * as styles from "./card.module.scss"
import CardImage from "./card-image/card-image"
import CardSvg from "./card-svg/card-svg"
import CardSplit from "./card-split/card-split"

type RenderProps = {
  title: string
  url: string
  date: string
  cardType: string
  backgroundSvg: string
  backgroundImage: {
    url: string
    alt: string
  }
  backgroundColor?: string
  backgroundColorBis?: string
  textColor: string
}

const Card: React.FC<RenderProps> = ({
  title,
  url,
  date,
  cardType,
  backgroundSvg,
  backgroundImage,
  backgroundColor,
  backgroundColorBis,
  textColor,
}) => {
  return (
    <li className={styles.card} style={{ color: textColor }}>
      <a href={url}>
        {cardType === "image" ? (
          <CardImage title={title} date={date} backgroundImage={backgroundImage} />
        ) : cardType === "svg" ? (
          <CardSvg
            title={title}
            date={date}
            backgroundSvg={backgroundSvg}
            backgroundColor={backgroundColor}
            backgroundColorBis={backgroundColorBis}
          />
        ) : (
          cardType === "split" && (
            <CardSplit
              title={title}
              date={date}
              backgroundSvg={backgroundSvg}
              backgroundImage={backgroundImage}
              backgroundColor={backgroundColor}
              backgroundColorBis={backgroundColorBis}
            />
          )
        )}
      </a>
    </li>
  )
}

export default Card
