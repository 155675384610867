import React, { createContext, useContext } from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, InternalExternalLinkBlockFieldsFragment } from "../../../graphql-types"
import { useLocale } from "./locale-context"

type SlugsProviderProps = {
  children: React.ReactNode
  slugs: DatoCmsSlugsConfiguration
}

type SlugsContextData = {
  slugs: DatoCmsSlugsConfiguration
  homeLink: string
  pulsePageSlug: string
  newsroomPageSlug: string
  pressReleasesPageSlug: string
  newsPageSlug: string
  publicationsPageSlug: string
  contactPageSlug: string
  governanceMembersPageSlug: string
  parentPageSlug: string
  detailNetworkPageSlug: string
  mapPageSlug: string
  searchPageSlug: string
  buildInternalLink: (internalLink: InternalLink) => string
  getInternalExternalLink: (link: InternalExternalLinkBlockFieldsFragment) => string
}

type InternalLink = {
  __typename: string
  slug?: string
}

const SlugsContext = createContext<SlugsContextData | undefined>(undefined)

export const useSlugs = () => {
  const context = useContext(SlugsContext)

  if (!context) {
    throw new Error("useSlugs must be used inside SlugsProvider")
  }

  return context
}

export const SlugsProvider: React.FC<SlugsProviderProps> = ({ children, slugs }) => {
  const { locale, defaultLocale } = useLocale()

  const homeLink = locale === defaultLocale ? `/` : `/${locale}`
  const pulsePageSlug = slugs._allPulseHomePageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const newsroomPageSlug = slugs._allNewsroomPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const pressReleasesPageSlug =
    slugs._allPressReleasesPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const newsPageSlug = slugs._allNewsPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const publicationsPageSlug = slugs._allPublicationsPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const contactPageSlug = slugs._allContactPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const governanceMembersPageSlug =
    slugs._allGovernanceMembersPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const parentPageSlug = slugs._allPageParentSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const detailNetworkPageSlug = slugs._allDetailNetworkSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const mapPageSlug = slugs._allMapPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""
  const searchPageSlug = slugs._allSearchPageSlugLocales?.find(slug => slug.locale === locale)?.value || ""

  const buildInternalLink = (internalLink: InternalLink) => {
    if (!internalLink) return null

    switch (internalLink.__typename) {
      case "DatoCmsHomePage":
        return homeLink
      case "DatoCmsPage":
      case "DatoCmsGeneratedPage":
        return locale === defaultLocale ? `/${internalLink.slug}` : `/${locale}/${internalLink.slug}`
      case "DatoCmsNews":
        return locale === defaultLocale
          ? `/${newsroomPageSlug}/${newsPageSlug}/${internalLink.slug}`
          : `/${locale}/${newsroomPageSlug}/${newsPageSlug}/${internalLink.slug}`
      case "DatoCmsPressRelease":
        return locale === defaultLocale
          ? `/${newsroomPageSlug}/${pressReleasesPageSlug}/${internalLink.slug}`
          : `/${locale}/${newsroomPageSlug}/${pressReleasesPageSlug}/${internalLink.slug}`
      case "DatoCmsParentPage":
        return locale === defaultLocale
          ? `/${parentPageSlug}/${internalLink.slug}`
          : `/${locale}/${parentPageSlug}/${internalLink.slug}`
      case "DatoCmsThematicPulse":
        return locale === defaultLocale
          ? `/${pulsePageSlug}/${internalLink.slug}`
          : `/${locale}/${pulsePageSlug}/${internalLink.slug}`
      case "DatoCmsGovernanceMember":
        return locale === defaultLocale
          ? `/${governanceMembersPageSlug}/${internalLink.slug}`
          : `/${locale}/${governanceMembersPageSlug}/${internalLink.slug}`
    }
  }

  const getInternalExternalLink = (link: InternalExternalLinkBlockFieldsFragment) => {
    if (link.isInternalPage) {
      return buildInternalLink(link.internalLink as InternalLink)
    } else if (link.externalLink) {
      return link.externalLink
    }
    return "/"
  }

  return (
    <SlugsContext.Provider
      value={{
        slugs,
        homeLink,
        pulsePageSlug,
        newsroomPageSlug,
        pressReleasesPageSlug,
        newsPageSlug,
        publicationsPageSlug,
        contactPageSlug,
        governanceMembersPageSlug,
        parentPageSlug,
        detailNetworkPageSlug,
        mapPageSlug,
        searchPageSlug,
        buildInternalLink,
        getInternalExternalLink,
      }}
    >
      {children}
    </SlugsContext.Provider>
  )
}
//
export const fragment = graphql`
  fragment InternalExternalLinkBlockFields on DatoCmsInternalExternalLinkBlock {
    id
    title
    isInternalPage
    externalLink
    internalLink {
      __typename
      ... on DatoCmsPage {
        slug
      }
      ... on DatoCmsNews {
        slug
      }
      ... on DatoCmsPressRelease {
        slug
      }
      ... on DatoCmsParentPage {
        slug
      }
      ... on DatoCmsThematicPulse {
        slug
      }
      ... on DatoCmsGeneratedPage {
        slug
      }
    }
  }
`
