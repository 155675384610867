import React from "react"
import * as styles from "./highlight.module.scss"
import { graphql } from "gatsby"
import { HighlightFieldsFragment, V3BlockSameTopicFieldsFragment } from "../../../../graphql-types"
import Card from "./card/card"
import { useAssociatedColor, useIsMobile } from "../../hooks"

type RenderProps = {
  data: HighlightFieldsFragment | V3BlockSameTopicFieldsFragment
}

const HighLight: React.FC<RenderProps> = ({ data }) => {
  const isMobile = useIsMobile()
  const redirectLink = data.__typename === "DatoCmsHomePageHighlight" ? data.redirectLink : data.redirectLink[0]
  return (
    <div
      className={styles.highlight}
      style={!isMobile ? { backgroundImage: `url(${data.backgroundImage?.url})` } : null}
    >
      {isMobile && <img src={data.backgroundImage?.urlMobile} alt="Background" className={styles.mobileImage} />}
      <div className={styles.highlightContent}>
        <Card
          // title={data.redirectLink?.title || data.redirectLink?.[0].title}
          title={redirectLink.title}
          backgroundSvg="/vectors/v3/mediaCards/card1.svg"
          backgroundColor={data.cardBackgroundColor.hex}
          backgroundColorBis={useAssociatedColor(data.cardBackgroundColor.hex)}
          textColor={data.cardTextColor.hex}
          buttonText={data.buttonText}
          redirectLink={redirectLink}
        />
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment V3BlockSameTopicFields on DatoCmsV3BlockSameTopic {
    __typename
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
    cardBackgroundColor {
      hex
    }
    cardTextColor {
      hex
    }
    backgroundImage {
      alt
      url
      urlMobile: url(imgixParams: { fit: "crop", w: "351", h: "372" })
    }
    buttonText
  }
  fragment HighlightFields on DatoCmsHomePageHighlight {
    __typename
    isEnabled
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
    cardBackgroundColor {
      hex
    }
    cardTextColor {
      hex
    }
    backgroundImage {
      alt
      url
      urlMobile: url(imgixParams: { fit: "crop", w: "351", h: "372" })
    }
    buttonText
  }
`

export default HighLight
