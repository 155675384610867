import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, ParentPageQuery } from "../../../../graphql-types"
import * as styles from "./parent-page.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue } from "../../hooks"
import Layout from "../../components/layout/layout"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import Banner from "../../components/banner/banner"
import Presentation from "../../components/rich-dato-cms-content/presentation/presentation"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: ParentPageQuery
  location: Location
  pageContext: {
    id: string
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
    allSlugLocales: { locale: string; value: string }[]
  }
}

const ParentPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allPageParentSlugLocales, pageContext.locale)}
      pageTemplate="parent"
    >
      <Banner title={data.datoCmsParentPage.title} image={data.datoCmsParentPage.image} />

      <section className={styles.container}>
        {!isMobile && (
          <Breadcrumb title={data.datoCmsParentPage.title} homeLabel={data.datoCmsGlobalConfiguration.homePageTitle} />
        )}

        <Presentation data={data.datoCmsParentPage} isParentPage={true} />
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsParentPage.titleTag}
      description={data.datoCmsParentPage.metaDescription}
      noindex={data.datoCmsParentPage.noindex}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <>
        <link
          rel="canonical"
          href={data.datoCmsParentPage.isCanonical ? data.datoCmsParentPage.urlCanonical : window.location.href}
        />
        <HrefLangLinks
          defaultDomain={window.location.origin}
          defaultLocale={pageContext.defaultLocale}
          slugs={[pageContext.slugs._allPageParentSlugLocales, pageContext.allSlugLocales]}
        />
      </>
    )}
  </>
)

export const pageQuery = graphql`
  query ParentPage($locale: String!, $id: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsParentPage(locale: $locale, id: { eq: $id }) {
      ...V3ParentPageFields
    }
  }
`

export const fragment = graphql`
  fragment V3ParentPageFields on DatoCmsParentPage {
    id
    titleTag
    metaDescription
    noindex
    isCanonical
    urlCanonical
    title
    subtitle
    colorTitle {
      hex
    }
    colorSubtitle {
      hex
    }
    slug
    image {
      alt
      format
      title
      url
    }
    chapo
    # button {
    #   ...ParentPageButtonFields
    # }
    ...V3PresentationParentPageFields
    displayMobility
  }
`

export default ParentPage
