export const useIsDateInRange = (start: string, end: string) => {
  const startDate = new Date(start)
  const endDate = end ? new Date(end) : null
  const todayDate = new Date()

  // IF end date is not provided, only check if start date is in range
  if (!startDate || todayDate < startDate || (endDate && todayDate > endDate)) {
    return false
  } else if (startDate <= todayDate && (!endDate || todayDate < endDate)) {
    return true
  }
}
