import React from "react"
import { ReactSVG } from "react-svg"
import { InternalExternalLinkBlockFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./card.module.scss"
import sanitizeHtml from "sanitize-html"
import { useFormatDate, useIsMobile } from "../../../../hooks"
import { useLocale } from "../../../../context/locale-context"
import { useSlugs } from "../../../../context/slugs-context"

type RenderProps = {
  title: string
  text: string
  backgroundSvg: string
  backgroundColor: string
  backgroundColorBis: string
  textColor: string
  redirectLink: InternalExternalLinkBlockFieldsFragment
}

const Card: React.FC<RenderProps> = ({
  title,
  text,
  backgroundSvg,
  backgroundColor,
  backgroundColorBis,
  textColor,
  redirectLink,
}) => {
  const style = {
    "--text-color": textColor,
    "--background-color": backgroundColor,
    "--background-color-bis": backgroundColorBis,
  } as React.CSSProperties

  const isMobile = useIsMobile()
  const { getInternalExternalLink } = useSlugs()
  const { locale } = useLocale()

  const hasPublicationDate = (link: any): link is { publicationDate: string } =>
    typeof link.publicationDate === "string"

  const cardContent = (
    <div className={styles.card} style={style}>
      <ReactSVG
        src={backgroundSvg}
        beforeInjection={svg => {
          if (!isMobile) svg.setAttribute("preserveAspectRatio", "xMidYMid meet")
        }}
        afterInjection={svg => {
          svg.querySelector("rect").style.fill = backgroundColor
          const paths = svg.querySelectorAll("path")
          paths.forEach(path => (path.style.fill = backgroundColorBis))
        }}
      />
      <div className={styles.cardContent}>
        <div className={styles.title}>{title}</div>
        <div className={styles.chapo} dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />
        {redirectLink.isInternalPage &&
          hasPublicationDate(redirectLink.internalLink) &&
          redirectLink.internalLink.publicationDate !== "" && (
            <div className={styles.date}>{useFormatDate(redirectLink.internalLink.publicationDate, locale)}</div>
          )}
      </div>
    </div>
  )

  return (
    <>
      {getInternalExternalLink(redirectLink) !== "/" ? (
        <a href={getInternalExternalLink(redirectLink)} target={redirectLink?.isInternalPage ? "_self" : "_blank"}>
          {cardContent}
        </a>
      ) : (
        <>{cardContent}</>
      )}
    </>
  )
}

export default Card
