export const MAP_CUSTOM_STYLE = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{ color: "#005f7d" }],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "water",
    elementType: "geometry.stroke",
    stylers: [{ color: "#005f7d" }],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{ color: "#c2e6f5" }],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
]

export const GOOGLE_MAP_API_PARAMETERS = {
  id: "a37a3e7234174de",
  googleMapsApiKey: "AIzaSyCDlsgNMQRvHDQ57SbGOeOBFBUPcksbmdY",
}

export const DEFAULT_MAP_PARAMETERS = {
  latitude: 29.729,
  longitude: 2.2137,
  zoom: 4.9,
  defaultZoom: 2,
  mobileZoom: 3.5,
  cartographyZoom: 2.75,
}

export const MAP_CONTAINER_STYLE = {
  width: "100%",
  height: "100%",
}

export const POLYGON_COLOR = "#00AAC3"
export const POLYGON_SELECTED_COLOR = "#124162"

export const PAGE_NOT_FOUND_ELEMENTS = {
  title: "Oups !",
  breadcrumbHomeLabel: "Accueil",
  secondaryMessage: {
    text: "La page que vous recherchez semble introuvable.",
    errorCode: "Code d'erreur: 404",
  },
  bouttonText: "Retour accueil",
}
