import React from "react"
import { ContactPageFormFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./success-page.module.scss"
import RichDatoCmsContent from "../../../components/rich-dato-cms-content/rich_dato_cms_content"
import bus from "../../../../../static/images/bus.png"

const SuccessPage = ({ data }: { data: ContactPageFormFieldsFragment }) => {
  return (
    <div className={styles.container}>
      <span>{data.successTitle}</span>
      <img className={styles.image} src={bus} alt="" loading="eager" />
      <div className={styles.textContainer}>
        <RichDatoCmsContent data={data.successMessage} />
      </div>
    </div>
  )
}

export default SuccessPage
