import React from "react"
import { graphql } from "gatsby"
import { KeyFiguresFieldsFragment, V3KeyFiguresBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./key-figures.module.scss"
import { useSlugs } from "../../context/slugs-context"

type RenderProps = {
  data: V3KeyFiguresBlockFieldsFragment | KeyFiguresFieldsFragment
}

const KeyFigures: React.FC<RenderProps> = ({ data }) => {
  const { getInternalExternalLink } = useSlugs()

  return (
    <div className={`${styles.container} ${data.isBackgroundDisplayed ? styles.background : ""}`}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{data.title}</h2>
      </div>
      <ul className={styles.keyFiguresContainer}>
        {data.keyFigures?.map((kf, index) => (
          <li className={styles.keyFigure} key={index}>
            {kf.redirectLink?.[0] ? (
              <a
                href={getInternalExternalLink(kf.redirectLink?.[0])}
                target={kf.redirectLink?.[0].isInternalPage ? "_self" : "_blank"}
                className={styles.redirectLink}
              >
                <span className={styles.keyFigureTitle}>{kf.value}</span>
              </a>
            ) : (
              <span className={styles.keyFigureTitle}>{kf.value}</span>
            )}
            {kf.description && <span className={styles.keyFigureDescription}>{kf.description}</span>}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const fragment = graphql`
  fragment KeyFiguresFields on DatoCmsHomePageKeyFigure {
    isEnabled
    title
    isBackgroundDisplayed
    keyFigures {
      value
      description
      redirectLink {
        ...InternalExternalLinkBlockFields
      }
    }
  }

  fragment V3KeyFiguresBlockFields on DatoCmsV3KeyFiguresBlock {
    title
    isBackgroundDisplayed
    keyFigures {
      value
      description
      redirectLink {
        ...InternalExternalLinkBlockFields
      }
    }
  }
`

export default KeyFigures
