// extracted by mini-css-extract-plugin
export var blockTitle = "network-contact-module--blockTitle--2a46b";
export var buttonWrapper = "network-contact-module--buttonWrapper--e6559";
export var leftContent = "network-contact-module--leftContent--75397";
export var lineLink = "network-contact-module--lineLink--3f32d";
export var networkContactWrapper = "network-contact-module--networkContactWrapper--59524";
export var pictogram = "network-contact-module--pictogram--0348c";
export var rightContent = "network-contact-module--rightContent--b8868";
export var socialMedia = "network-contact-module--socialMedia--3ae9f";
export var socialNetworkLink = "network-contact-module--socialNetworkLink--204f8";
export var subtitle = "network-contact-module--subtitle--a07c9";