import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { FooterFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./footer.module.scss"
import { useSlugs } from "../../../context/slugs-context"
import { useIsMobile } from "../../../hooks"
import SiteMapColumns from "./site-map-columns/site-map-columns"
import SocialNetworksColumn from "./social-networks-column/social-networks-column"
import LegalDocumentsColumn from "./legal-documents-column/legal-documents-column"
import LogoMobile from "../../../../../static/vectors/v3/keolis-logo-mobile.svg"

type RenderProps = {
  data: FooterFieldsFragment
}

const Footer: React.FC<RenderProps> = ({ data }) => {
  const { homeLink } = useSlugs()
  const isMobile = useIsMobile()

  return !isMobile ? (
    <footer data-datocms-noindex={true} role="contentinfo">
      <div className={styles.container}>
        <a href={homeLink} className={styles.logoLink}>
          <img src={data.logo.url} alt={data.logo.alt} className={styles.logoImg} />
        </a>
        <div className={styles.columns}>
          <SiteMapColumns columns={data.siteMapColumns} />
          <SocialNetworksColumn title={data.socialNetworksColumnTitle} column={data.socialNetworksColumn} />
        </div>
      </div>
      <div className={styles.separator}></div>
      <LegalDocumentsColumn column={data.legalDocumentsColumn} />
    </footer>
  ) : (
    <footer data-datocms-noindex={true} role="contentinfo">
      <div className={styles.container}>
        <div className={styles.flexLogos}>
          <a href={homeLink} className={styles.logoLink}>
            {data.logoMobile ? (
              <img src={data.logoMobile.url} alt={data.logoMobile.alt} className={styles.logoImg} />
            ) : (
              <ReactSVG src={LogoMobile} className={styles.logoImg} aria-hidden={true} />
            )}
          </a>
          <SocialNetworksColumn column={data.socialNetworksColumn} />
        </div>
      </div>
      <LegalDocumentsColumn column={data.legalDocumentsColumn} />
    </footer>
  )
}

export const fragment = graphql`
  fragment FooterFields on DatoCmsFooter {
    logo {
      alt
      url
    }
    logoMobile {
      alt
      url
    }
    siteMapColumns {
      ...SiteMapColumnsFields
    }
    socialNetworksColumnTitle
    socialNetworksColumn {
      ...SocialNetworksColumnFields
    }
    legalDocumentsColumn {
      ...LegalDocumentsColumnFields
    }
  }
`

export default Footer
