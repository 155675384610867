import React, { useState } from "react"
import { graphql } from "gatsby"
import { AllNewsPageQuery, DatoCmsSlugsConfiguration } from "../../../../../graphql-types"
import * as styles from "../all-page.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue, useSortArticlesByMonth } from "../../../hooks"
import Layout from "../../../components/layout/layout"
import Breadcrumb from "../../../components/breadcrumb/breadcrumb"
import Pagination from "../../../components/pagination/pagination"
import MediaCards from "../../../components/media-cards/media-cards"
import SEO from "../../../components/seo/seo"
import { HrefLangLinks } from "../../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: AllNewsPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const AllNewsPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const news = useSortArticlesByMonth(data.allDatoCmsNews.nodes, pageContext.locale)
  const [filteredNews, setFilteredNews] = useState(news)
  const currentRoute = `${useSlugValue(
    pageContext.slugs._allNewsroomPageSlugLocales,
    pageContext.locale
  )}/${useSlugValue(pageContext.slugs._allNewsPageSlugLocales, pageContext.locale)}`

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={currentRoute}
      pageTemplate="all news"
    >
      <section className={styles.container}>
        {!isMobile && (
          <Breadcrumb
            title={data.datoCmsNewsroom.allNewsPageTitle}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={[
              {
                id: "newsroom",
                title: data.datoCmsNewsroom.newsroomPageTitle,
                isInternalPage: true,
                internalLink: {
                  __typename: "DatoCmsGeneratedPage",
                  slug: useSlugValue(pageContext.slugs._allNewsroomPageSlugLocales, pageContext.locale),
                },
              },
            ]}
          />
        )}
        <h1 className={styles.title}>{data.datoCmsNewsroom.allNewsPageTitle}</h1>

        <Pagination articles={news} filteredArticles={filteredNews} onSelect={setFilteredNews} />

        {filteredNews?.map(monthlyNews => (
          <MediaCards
            title={monthlyNews.publicationDate}
            news={monthlyNews.articles}
            multiRow={!isMobile}
            key={monthlyNews.publicationDate}
          />
        ))}
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsNewsroom.newsroomPageTitle}
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allNewsroomPageSlugLocales]}
      />
    )}
  </>
)
export const fragment = graphql`
  fragment NewsroomLiveFields on DatoCmsNewsroom {
    id
    newsroomLiveIsEnabled
    newsroomLiveTitle
    newsroomLiveSocialNetworkLabel
    newsroomLivePlaylistUrl
    newsroomLiveButtonRedirectionLabel
    newsroomLiveButtonRedirectionPictogram {
      alt
      format
      url
    }
    newsroomLiveButtonRedirectionPictogramHovered {
      alt
      format
      url
    }
    newsroomNewsRedirectionPage {
      slug
    }
  }
`
export const pageQuery = graphql`
  query AllNewsPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }
    allDatoCmsNews(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsNews {
          ...NewsMediaFields
        }
      }
    }
    datoCmsNewsroom(locale: $locale) {
      newsroomPageTitle
      allNewsPageTitle
      ...NewsroomLiveFields
    }
  }
`

export default AllNewsPage
