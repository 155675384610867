import React, { useState } from "react"
import { graphql } from "gatsby"
import { V3DiaporamaFieldsFragment } from "../../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import * as styles from "./v3-diaporama.module.scss"
import ScrollArrow from "../../tools/scroll-arrow/scroll-arrow"

type RenderProps = {
  data: V3DiaporamaFieldsFragment
}

const V3Diaporama: React.FC<RenderProps> = ({ data }) => {
  const [index, setIndex] = useState(0)
  const element = data.diaporama[index]
  const sanitizedText = sanitizeHtml(element.heading)

  const handleScroll = (scrollDirection: number) => {
    if (scrollDirection === 1 && index < data.diaporama.length - 1) {
      setIndex(index + 1)
    } else if (scrollDirection === -1 && index > 0) {
      setIndex(index - 1)
    }
  }

  return (
    <div className={styles.diaporamaContainer}>
      <div className={styles.heading}>
        {sanitizedText.length > 0 && <p className={styles.text} dangerouslySetInnerHTML={{ __html: sanitizedText }} />}
        {data.diaporama.length > 1 && (
          <div className={styles.arrowsContainer}>
            <ScrollArrow direction="left" onClick={() => handleScroll(-1)} />
            <ScrollArrow direction="right" onClick={() => handleScroll(1)} />
          </div>
        )}
      </div>
      <div className={styles.image}>
        {element.__typename === "DatoCmsV3Image" ? (
          <img src={element.image.url} alt={element.image.alt} />
        ) : (
          <iframe
            src={element.url}
            title="Video player"
            tabIndex={-1}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation allow-modals allow-top-navigation"
            loading="lazy"
            allowFullScreen
          />
        )}
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment V3DiaporamaFields on DatoCmsV3Diaporama {
    __typename
    diaporama {
      ... on DatoCmsV3Image {
        ...V3ImageFields
      }
      ... on DatoCmsV3Video {
        ...V3VideoFields
      }
    }
  }
`

export default V3Diaporama
