import React from "react"
import { InternalExternalLinkBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./breadcrumb.module.scss"
import { useLocale } from "../../context/locale-context"
import { useSlugs } from "../../context/slugs-context"

type RenderProps = {
  title: string
  homeLabel: string
  middlePages?: InternalExternalLinkBlockFieldsFragment[]
}

const Breadcrumb: React.FC<RenderProps> = ({ title, homeLabel, middlePages }) => {
  const { locale } = useLocale()
  const { homeLink, getInternalExternalLink } = useSlugs()

  return (
    <ul className={styles.breadcrumb} data-datocms-noindex={true}>
      <li>
        <a className={styles.home} href={homeLink}>
          {homeLabel}
        </a>
      </li>
      {middlePages?.map(
        middlePage =>
          middlePage?.title && (
            <li className={styles.breadcrumbContainer} key={middlePage.title}>
              <p className={styles.pElement}>{">"}</p>
              <a
                href={getInternalExternalLink(middlePage)}
                className={styles.home}
                lang={locale !== "en" && middlePage.title === "Newsroom" ? "en" : null}
              >
                {middlePage.title}
              </a>
            </li>
          )
      )}
      {title && (
        <li className={styles.breadcrumbContainer}>
          <p className={styles.pElement}>{">"}</p>
          <p className={styles.lastPage} lang={locale !== "en" && title === "Newsroom" ? "en" : null}>
            {title}
          </p>
        </li>
      )}
    </ul>
  )
}

export default Breadcrumb
