import * as config from "../../../config"

type SendEmailV2Params = {
  trigram: string
  idForm: string
  email?: string
  fields?: { key: string; value: string }[]
  acceptTerms?: string
  header?: string
  footer?: string
  logo?: string
  primaryColor?: string
  locale?: string
}

export const sendEmailV2 = ({
  trigram,
  idForm,
  email,
  fields,
  acceptTerms,
  header,
  footer,
  logo,
  primaryColor,
  locale,
}: SendEmailV2Params): Promise<Response> => {
  const endpoint = config.serverless_sendmail_endpoint
  const ocpmSubscriptionKey = config.serverless_sendmail_token
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": ocpmSubscriptionKey,
  }

  const body = JSON.stringify({
    trigram: trigram.toLocaleUpperCase(),
    idForm,
    email,
    fields,
    acceptTerms,
    header,
    footer,
    logo,
    primaryColor,
    locale,
  })

  return fetch(endpoint, {
    method: "POST",
    headers,
    body: body,
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response
  })
}
