import React, { useState } from "react"
import { graphql } from "gatsby"
import { AllPublicationsPageQuery, DatoCmsSlugsConfiguration } from "../../../../../graphql-types"
import * as styles from "../all-page.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue, useSortArticlesByMonth } from "../../../hooks"
import Layout from "../../../components/layout/layout"
import Breadcrumb from "../../../components/breadcrumb/breadcrumb"
import FilterBar from "../../../components/tools/filter-bar/filter-bar"
import Pagination from "../../../components/pagination/pagination"
import MediaCards from "../../../components/media-cards/media-cards"
import SEO from "../../../components/seo/seo"
import { HrefLangLinks } from "../../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: AllPublicationsPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const AllPublicationsPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const publications = useSortArticlesByMonth(data.allDatoCmsPublication.nodes, pageContext.locale)
  const currentRoute = `${useSlugValue(
    pageContext.slugs._allNewsroomPageSlugLocales,
    pageContext.locale
  )}/${useSlugValue(pageContext.slugs._allPublicationsPageSlugLocales, pageContext.locale)}`

  const [activeCategory, setActiveCategory] = useState("")
  const [filteredPublications, setFilteredPublications] = useState(publications)

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={currentRoute}
      pageTemplate="all publications"
    >
      <section className={styles.container}>
        {!isMobile && (
          <Breadcrumb
            title={data.datoCmsNewsroom.allPublicationsPageTitle}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={[
              {
                id: "newsroom",
                title: data.datoCmsNewsroom.newsroomPageTitle,
                isInternalPage: true,
                internalLink: {
                  __typename: "DatoCmsGeneratedPage",
                  slug: useSlugValue(pageContext.slugs._allNewsroomPageSlugLocales, pageContext.locale),
                },
              },
            ]}
          />
        )}
        <div className={styles.titleBar}>
          <h1 className={styles.title}>{data.datoCmsNewsroom.allPublicationsPageTitle}</h1>
          <div className={styles.filterBar}>
            <FilterBar
              capsules={data.datoCmsPublicationCategoryList.categories.map(filter => ({
                label: filter.category,
                onClick: () => {
                  if (activeCategory !== filter.category) {
                    const filtered = useSortArticlesByMonth(
                      data.allDatoCmsPublication.nodes.filter(p => p.category?.category === filter.category),
                      pageContext.locale
                    )
                    setFilteredPublications(filtered)
                    setActiveCategory(filter.category)
                  } else {
                    setFilteredPublications(publications)
                    setActiveCategory("")
                  }
                },
                isActive: filter.category === activeCategory,
              }))}
            />
          </div>
        </div>

        <Pagination
          articles={publications}
          filteredArticles={filteredPublications}
          onSelect={setFilteredPublications}
        />

        {filteredPublications?.map((monthlyPublications, index) => (
          <MediaCards
            title={monthlyPublications.publicationDate}
            publications={monthlyPublications.articles}
            multiRow={!isMobile}
            key={`${monthlyPublications.publicationDate}-${index}`}
          />
        ))}
      </section>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsNewsroom.newsroomPageTitle}
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        slugs={[pageContext.slugs._allNewsroomPageSlugLocales]}
      />
    )}
  </>
)
export const fragments = graphql`
  fragment NewsroomPublicationsFields on DatoCmsNewsroom {
    newsroomPublicationsIsEnabled
    newsroomPublicationsTitle
    newsroomPublicationsText
    newsroomPublicationsIsHighlightedContent
    newsroomPublicationsHighlightedContent {
      ...PublicationFields
    }
    newsroomPublicationsButtonLabel
    newsroomPublicationsHighlightedContentCategory {
      ...PublicationCategoryFields
    }
  }
  fragment PublicationCategoryFields on DatoCmsPublicationCategory {
    category
  }
`
export const pageQuery = graphql`
  query AllPublicationsPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }
    allDatoCmsPublication(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsPublication {
          ...PublicationMediaFields
        }
      }
    }
    datoCmsPublicationCategoryList(locale: $locale) {
      categories {
        category
      }
    }
    datoCmsNewsroom(locale: $locale) {
      newsroomPageTitle
      allPublicationsPageTitle
      ...NewsroomLiveFields
    }
  }
`

export default AllPublicationsPage
