// extracted by mini-css-extract-plugin
export var chapo = "presentation-module--chapo--10519";
export var content = "presentation-module--content--05a2c";
export var contentReversed = "presentation-module--contentReversed--722b0";
export var image = "presentation-module--image--5b346";
export var imageWrapper = "presentation-module--imageWrapper--ff33d";
export var information = "presentation-module--information--b76ff";
export var link = "presentation-module--link--07b31";
export var linkContent = "presentation-module--linkContent--6356b";
export var linkContentText = "presentation-module--linkContentText--cdf70";
export var parentPagePadding = "presentation-module--parentPagePadding--a0f02";
export var presentation = "presentation-module--presentation--3113b";
export var selectedPageContent = "presentation-module--selectedPageContent--cdc6f";
export var selectedPageContentReversed = "presentation-module--selectedPageContentReversed--89dd2";
export var title = "presentation-module--title--70edc";