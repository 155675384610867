export const useAssociatedColor = (color: string) => {
  const colorPairs = [
    ["#008296", "#00aac3"], // keolis blue
    ["#bbe2f0", "#ecfaff"], // sky blue
    ["#183149", "#61809d"], // deep blue
    ["#554c43", "#928b83"], // warm grey
    ["#5f006e", "#9233a1"], // purple
    ["#484f0a", "#96b442"], // green
    ["#af3100", "#e16433"], // orange
    ["#ad915c", "#dac59d"], // sand
  ]

  const findAssociatedColor = (inputColor: string) => {
    for (const [color1, color2] of colorPairs) {
      if (inputColor.toLowerCase() === color1) return color2
      if (inputColor.toLowerCase() === color2) return color1
    }
    return ""
  }
  if (!color) return ""
  return findAssociatedColor(color)
}
