import React from "react"
import { graphql } from "gatsby"
import { V3ImageFieldsFragment } from "../../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import * as styles from "./v3-image.module.scss"

type RenderProps = {
  data: V3ImageFieldsFragment
}

const V3Image: React.FC<RenderProps> = ({ data }) => {
  const sanitizedText = sanitizeHtml(data.heading)

  return (
    <div className={styles.imageContainer}>
      <div className={styles.heading}>
        <p className={styles.text} dangerouslySetInnerHTML={{ __html: sanitizedText }} />
      </div>
      <div className={styles.image}>
        <img src={data.image.url} alt={data.image.alt} />
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment V3ImageFields on DatoCmsV3Image {
    __typename
    image {
      alt
      url
    }
    heading
  }
`

export default V3Image
