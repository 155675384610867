import React, { useRef } from "react"
import { graphql } from "gatsby"
import { V3RecruitmentFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./carousel.module.scss"
import { useLocale } from "../../../../context/locale-context"
import { useFormatDate } from "../../../../hooks"
import ScrollArrow from "../../../tools/scroll-arrow/scroll-arrow"
import { useSlugs } from "../../../../context/slugs-context"

type RenderProps = {
  article: V3RecruitmentFieldsFragment
  arrows: boolean
  onScroll: (scrollDirection: number) => void
}

const Carousel: React.FC<RenderProps> = ({ article, arrows, onScroll }) => {
  const { locale } = useLocale()
  const { getInternalExternalLink } = useSlugs()

  const cards = useRef(null)
  const publicationDate = (article as any).redirectLink.internalLink?.publicationDate
  const title = article.redirectLink.title || (article as any).redirectLink.internalLink?.title

  return (
    <>
      <div className={styles.cardsContainer} ref={cards}>
        <div className={styles.card} key={article.id}>
          {article.redirectLink.isInternalPage && publicationDate && (
            <span className={styles.date}>{useFormatDate(publicationDate, locale)}</span>
          )}
          <a
            href={getInternalExternalLink(article.redirectLink)}
            target={article.redirectLink.isInternalPage ? "_self" : "_blank"}
            className={styles.linkTitle}
          >
            <span className={styles.spanAnimated}>{title}</span>
          </a>
        </div>
      </div>
      {arrows && (
        <div className={styles.arrowsContainer}>
          <ScrollArrow direction="left" onClick={() => onScroll(-1)} />
          <ScrollArrow direction="right" onClick={() => onScroll(1)} />
        </div>
      )}
    </>
  )
}

export const fragment = graphql`
  fragment V3RecruitmentFields on DatoCmsV3Recruitment {
    id
    imageOrVideo
    image {
      alt
      url
    }
    urlVideo
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
  }
`

export default Carousel
