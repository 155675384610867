import { format } from "date-fns"
import { enGB, fr } from "date-fns/locale"

export const useFormatDate = (date: string, locale: string, shortFormat?: boolean) => {
  if (date === "") return ""

  const optionLocale = locale === "fr" ? fr : enGB
  const dateFormat = locale === "fr" ? "d MMMM yyyy" : "do MMMM yyyy"

  const formattedDate = format(new Date(date), dateFormat, { locale: optionLocale })
  const formattedDateWithOptions = new Intl.DateTimeFormat(locale, {
    month: "short",
    day: "numeric",
  }).format(new Date(date))

  if (locale === "fr" && formattedDate.startsWith("1 ")) {
    return "1er " + formattedDate.slice(2)
  }

  return shortFormat ? formattedDateWithOptions : formattedDate
}
