import React from "react"
import { graphql } from "gatsby"
import { V3QuoteFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./quote.module.scss"
import sanitizeHtml from "sanitize-html"
import { useFormatDate } from "../../../hooks"
import { useLocale } from "../../../context/locale-context"

type RenderProps = {
  data: V3QuoteFieldsFragment
}

const Quote: React.FC<RenderProps> = ({ data }) => {
  const { locale } = useLocale()
  return (
    <div className={styles.quoteBlock}>
      <p
        key={data.id}
        className={styles.quoteContent}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(data.quote),
        }}
      />

      <div className={styles.quoteAuthor}>
        <span className={styles.quoteDate}>{useFormatDate(data.date, locale)}</span> par {data.name}
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment V3QuoteFields on DatoCmsQuote {
    id
    name
    quote
    date
  }
`

export default Quote
