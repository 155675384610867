import React, { useState } from "react"
import { graphql } from "gatsby"
import { HeroBannerFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./hero-banner.module.scss"
import { useAssociatedColor, useIsMobile } from "../../../hooks"
import Card from "./card/card"
import ScrollArrow from "../../tools/scroll-arrow/scroll-arrow"

type RenderProps = {
  data: HeroBannerFieldsFragment
}

const HeroBanner: React.FC<RenderProps> = ({ data }) => {
  const isMobile = useIsMobile()
  const arrowStyleMobile = {
    border: "none",
    boxShadow: "0px 0px 16px 0px #394A641C",
  } as React.CSSProperties

  const [slideIndex, setSlideIndex] = useState(1)
  const handleArrowClick = (direction: "left" | "right") => {
    if (direction === "left") {
      if (slideIndex === 0) {
        setSlideIndex(data.carousel.length - 1)
      } else {
        setSlideIndex(slideIndex - 1)
      }
    } else {
      if (slideIndex === data.carousel.length - 1) {
        setSlideIndex(0)
      } else {
        setSlideIndex(slideIndex + 1)
      }
    }
  }

  return (
    <div className={styles.heroBanner}>
      <div className={styles.leftContainer}>
        <div className={styles.image} style={{ backgroundImage: `url(${data.carousel[slideIndex].image?.url})` }} />
        {!isMobile && (
          <div className={styles.arrowsContainer}>
            <ScrollArrow direction="left" onClick={() => handleArrowClick("left")} />
            <ScrollArrow direction="right" onClick={() => handleArrowClick("right")} />
          </div>
        )}
      </div>
      <div className={styles.card}>
        <Card
          title={data.carousel[slideIndex].redirectLink[0]?.title || data.carousel[slideIndex].title}
          text={data.carousel[slideIndex].chapo}
          backgroundSvg="/vectors/v3/hero-banner.svg"
          backgroundColor={data.carousel[slideIndex].cardBackgroundColor.hex}
          backgroundColorBis={useAssociatedColor(data.carousel[slideIndex].cardBackgroundColor.hex)}
          textColor={data.carousel[slideIndex].cardTextColor.hex}
          redirectLink={data.carousel[slideIndex].redirectLink[0]}
        />
      </div>
      {isMobile && (
        <div className={styles.arrowsContainer}>
          <ScrollArrow direction="left" style={arrowStyleMobile} onClick={() => handleArrowClick("left")} />
          <ScrollArrow direction="right" style={arrowStyleMobile} onClick={() => handleArrowClick("right")} />
        </div>
      )}
    </div>
  )
}

export const fragments = graphql`
  fragment HeroBannerFields on DatoCmsHomePageHeroBanner {
    isEnabled
    carousel {
      title
      chapo
      redirectLink {
        ...InternalExternalLinkBlockFields
      }
      cardBackgroundColor {
        hex
      }
      cardTextColor {
        hex
      }
      image {
        alt
        url
        urlMobile: url(imgixParams: { fit: "crop", w: "351", h: "372" })
      }
    }
  }
`

export default HeroBanner
