import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { V3SocialNetworkFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./meta-data.module.scss"
import Share from "../../../../../static/vectors/v3/share.svg"
import Pencil from "../../../../../static/vectors/v3/pencil.svg"
import Clock from "../../../../../static/vectors/v3/clock.svg"
import { useFormatDate } from "../../../hooks"
import { useLocale } from "../../../context/locale-context"

type RenderProps = {
  socialNetworks: V3SocialNetworkFieldsFragment[]
  shareLabel: string
  mailSubject: string
  publicationDate: string
}

const MetaData: React.FC<RenderProps> = ({ socialNetworks, shareLabel, mailSubject, publicationDate }) => {
  const pageToShare = typeof window !== "undefined" && window.location.href
  const { locale } = useLocale()
  return (
    <div className={styles.metaData}>
      <div className={styles.publicationDate}>
        <ReactSVG className={styles.icon} src={Pencil} />
        <span className={`${styles.label} ${styles.emphas}`}>{useFormatDate(publicationDate, locale)}</span>
        <ReactSVG className={styles.icon} src={Clock} />
        <div className={styles.label}> 2min</div>
      </div>

      <div className={styles.share}>
        <div className={styles.shareLabel}>
          <ReactSVG className={styles.icon} src={Share} />
          <span className={styles.label}>{shareLabel}</span>
        </div>
        <div className={styles.shareButton}>
          {socialNetworks.map((socialNetwork, index) => {
            const link = socialNetwork.link.startsWith("mailto")
              ? `mailto:?subject=${mailSubject}&body=${encodeURIComponent(pageToShare)}`
              : socialNetwork.link + pageToShare

            return (
              <a href={link} target="_blank" className={styles.socialNetwork} key={index}>
                <ReactSVG src={socialNetwork.image.url} />
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment V3SocialNetworkFields on DatoCmsV3SocialNetwork {
    isEnabled
    image {
      alt
      format
      title
      url
    }
    link
  }
`

export default MetaData
