import React from "react"
import { graphql } from "gatsby"
import { ImageFieldsFragment } from "../../../../graphql-types"
import * as styles from "./image.module.scss"

type RenderProps = {
  data: ImageFieldsFragment
}

const Image: React.FC<RenderProps> = ({ data }) => {
  const caption = data.caption && data.caption.value.document.children

  let captionAriaLabel = ""
  caption.map((c, index) => {
    captionAriaLabel += c.children[0].value
    if (index + 1 < caption.length) {
      captionAriaLabel += " "
    }
  })

  const buildRichText = (content: [{ type: string; value: string; marks?: string[] }], index: number) => {
    return (
      <p className={styles.paragraph} key={index}>
        {content.map(text => {
          return `${text.value} `
        })}
      </p>
    )
  }

  const buildHeadingText = (
    level: number,
    content: [{ type: string; value: string; marks?: string[] }],
    index: number
  ) => {
    switch (level) {
      case 1:
        return (
          <h1 className={`${styles.heading} ${styles.h1}`} key={index}>
            {content[0].value}
          </h1>
        )
      case 2:
        return (
          <h2 className={`${styles.heading} ${styles.h2}`} key={index}>
            {content[0].value}
          </h2>
        )
      case 3:
        return (
          <h3 className={`${styles.heading} ${styles.h3}`} key={index}>
            {content[0].value}
          </h3>
        )
      case 4:
        return (
          <h4 className={`${styles.heading} ${styles.h4}`} key={index}>
            {content[0].value}
          </h4>
        )
      case 5:
        return (
          <h5 className={`${styles.heading} ${styles.h5}`} key={index}>
            {content[0].value}
          </h5>
        )
    }
  }

  const buildStructuredContent = (
    type: string,
    level: number,
    content: [{ type: string; value: string; marks?: string[] }],
    index: number
  ) => {
    switch (type) {
      case "heading":
        return buildHeadingText(level, content, index)
      case "paragraph":
        return buildRichText(content, index)
    }
  }

  return (
    <figure className={styles.container} role="group" aria-label={captionAriaLabel}>
      <img className={styles.image} src={data.image.url} alt={data.image.alt || ""} loading="eager" />
      <figcaption>
        {caption &&
          caption.map((content, index) => buildStructuredContent(content.type, content.level, content.children, index))}
      </figcaption>
    </figure>
  )
}

export const fragment = graphql`
  fragment ImageFields on DatoCmsImage {
    __typename
    id
    image {
      alt
      url
    }
    caption {
      value
    }
  }
`

export default Image
