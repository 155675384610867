import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, HomeQuery } from "../../../../graphql-types"
import * as styles from "./home.module.scss"
import { useFormatLocale } from "../../hooks"
import Layout from "../../components/layout/layout"
import HeroBanner from "../../components/home/hero-banner/hero-banner"
import KeyFigures from "../../components/key-figures/key-figures"
import MediaCards from "../../components/media-cards/media-cards"
import Highlight from "../../components/highlight/highlight"
import MapModule from "../../components/home/map-module/map-module"
import Thematic from "../../components/home/thematic/thematic"
import Recruitment from "../../components/home/recruitment/recruitment"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: HomeQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const Home: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const renderContent = (content, index: number) => {
    if (!content.isEnabled) return

    switch (content?.__typename) {
      case "DatoCmsHomePageHeroBanner":
        return (
          <section id="KeolisHeroBanner" className={styles.container} key={index}>
            <HeroBanner data={content} />
          </section>
        )
      case "DatoCmsHomePageKeyFigure":
        return (
          <section id="KeolisKeyFigures" className={styles.container} key={index}>
            <KeyFigures data={content} />
          </section>
        )
      case "DatoCmsHomePageMediaNews":
        const newsroomPageSlug =
          pageContext.slugs._allNewsroomPageSlugLocales?.find(slug => slug.locale === pageContext.locale)?.value || ""
        return (
          <section id="KeolisMedia" className={`${styles.container} ${styles.mediaCardsContainer}`} key={index}>
            <MediaCards
              title={content.title}
              text={content.text}
              buttonLink={`${newsroomPageSlug}`}
              buttonLabel={content.buttonLabel}
              news={data.allDatoCmsNews.nodes}
              pressReleases={data.allDatoCmsPressRelease.nodes}
              publications={data.allDatoCmsPublication.nodes}
              filters={data.datoCmsNewsroom}
              limit={15}
            />
          </section>
        )
      case "DatoCmsHomePageHighlight":
        return (
          <section id="KeolisHighlight" className={styles.container} key={index}>
            <Highlight data={content} />
          </section>
        )
      case "DatoCmsHomePageMapModule":
        return (
          <section id="KeolisMapModule" className={styles.container} key={index}>
            <MapModule data={content} />
          </section>
        )
      case "DatoCmsHomePageThematic":
        return (
          <section id="KeolisThematic" className={styles.container} key={index}>
            <Thematic data={content} />
          </section>
        )
      case "DatoCmsV3HomePageRecruitment":
        return (
          <section id="KeolisRecruitment" className={styles.container} key={index}>
            <Recruitment data={content} />
          </section>
        )
    }
  }

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute="/"
      pageTemplate="homepage"
    >
      {data.datoCmsHomePage.content.map((content, index) => renderContent(content, index))}
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    {data.datoCmsGlobalConfiguration.tokenGoogleSiteVerification && (
      <meta name="google-site-verification" content={data.datoCmsGlobalConfiguration.tokenGoogleSiteVerification} />
    )}
    <SEO description={data.datoCmsGlobalConfiguration.metaDescription} locale={useFormatLocale(pageContext.locale)} />
    {typeof window !== "undefined" && (
      <>
        <link rel="canonical" href={window.location.href} />
        <HrefLangLinks defaultDomain={window.location.origin} defaultLocale={pageContext.defaultLocale} />
      </>
    )}
  </>
)

export const pageQuery = graphql`
  query Home($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      tokenGoogleSiteVerification
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }
    datoCmsHomePage(locale: $locale) {
      content {
        __typename
        ...MediaNewsFields
        ...KeyFiguresFields
        ...HighlightFields
        ...HeroBannerFields
        ...MapModuleFields
        ...ThematicFields
        ...V3HomePageRecruitmentFields
      }
    }
    allDatoCmsPressRelease(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsPressRelease {
          ...PressReleaseMediaFields
        }
      }
    }
    allDatoCmsPublication(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsPublication {
          ...PublicationMediaFields
        }
      }
    }
    allDatoCmsNews(
      locale: $locale
      sort: { publicationDate: DESC }
      filter: { locales: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ... on DatoCmsNews {
          ...NewsMediaFields
        }
      }
    }
    datoCmsNewsroom(locale: $locale) {
      ...NewsroomFilterTitlesFields
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }
  }
`

export default Home
