// extracted by mini-css-extract-plugin
export var activeSite = "network-details-module--activeSite--7fec7";
export var alignRight = "network-details-module--alignRight--09988";
export var backBracket = "network-details-module--backBracket--a75ff";
export var backButton = "network-details-module--backButton--2251f";
export var icon = "network-details-module--icon--b5c66";
export var label = "network-details-module--label--efe66";
export var networkLinks = "network-details-module--networkLinks--f225d";
export var networkName = "network-details-module--networkName--2f2c2";
export var otherSites = "network-details-module--otherSites--0fb7b";
export var pictogram = "network-details-module--pictogram--8746c";
export var site = "network-details-module--site--cd6a5";
export var socialNetwork = "network-details-module--socialNetwork--af5e6";
export var socialNetworks = "network-details-module--socialNetworks--8d806";
export var socialNetworksList = "network-details-module--socialNetworksList--3d94b";
export var transportMode = "network-details-module--transportMode--b2cb0";
export var transportModes = "network-details-module--transportModes--45964";