import React from "react"
import * as styles from "./card-image.module.scss"
import { useFormatDate } from "../../../../hooks"
import { useLocale } from "../../../../context/locale-context"

type RenderProps = {
  title: string
  date: string
  backgroundImage: {
    url: string
    alt: string
  }
}

const CardImage: React.FC<RenderProps> = ({ title, date, backgroundImage }) => {
  const { locale } = useLocale()

  return (
    <div className={styles.card}>
      <img className={styles.image} src={backgroundImage?.url} alt={backgroundImage?.alt || ""} />
      <div className={styles.cardContent}>
        <div className={styles.date}>{useFormatDate(date, locale)} </div>
        <div className={styles.title}>{title} </div>
        <div />
      </div>
    </div>
  )
}

export default CardImage
