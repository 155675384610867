import React, { useEffect } from "react"
import TagManager from "react-gtm-module"
import {
  DatoCmsFileField,
  DatoCmsSlugsConfiguration,
  FooterFieldsFragment,
  V3ContactButtonFieldsFragment,
  V3HeaderFieldsFragment,
} from "../../../../graphql-types"
import * as config from "../../../../config"
import { LocaleProvider } from "../../context/locale-context"
import { SlugsProvider } from "../../context/slugs-context"
import Header, { enableDidomi, enableGoogleTagManagerBody } from "./header/header"
import Footer from "./footer/footer"
import ButtonSticky from "./button-sticky/button-sticky"

type RenderProps = {
  children?: React.ReactNode
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
  location: Location
  header: V3HeaderFieldsFragment
  footer: FooterFieldsFragment
  subsidiaryLogo: DatoCmsFileField
  contactButton: V3ContactButtonFieldsFragment
  currentRoute: string
  pageTemplate: string
  allSlugLocales?: { locale: string; value: string }[]
}

const Layout: React.FC<RenderProps> = ({
  children,
  location,
  pageContext,
  header,
  footer,
  subsidiaryLogo,
  contactButton,
  currentRoute,
  pageTemplate,
  allSlugLocales,
}) => {
  useEffect(() => {
    enableDidomi()
    if (config.google_tag_manager_id) {
      enableGoogleTagManagerBody()
      TagManager.initialize({ gtmId: config.google_tag_manager_id })
      TagManager.dataLayer({
        dataLayer: { environment: process.env.NODE_ENV, language: pageContext.locale, pageTemplate },
      })
    }
  }, [])

  return (
    <LocaleProvider locale={pageContext.locale} defaultLocale={pageContext.defaultLocale}>
      <SlugsProvider slugs={pageContext.slugs}>
        <Header
          data={header}
          subsidiaryLogo={subsidiaryLogo}
          currentRoute={currentRoute}
          allSlugLocales={allSlugLocales}
        />
        <main id="main" role="main" tabIndex={-1}>
          {children}
          {contactButton?.isEnabled && <ButtonSticky data={contactButton} />}
        </main>
        <Footer data={footer} />
      </SlugsProvider>
    </LocaleProvider>
  )
}

export default Layout
