import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { V3SubmenuHeaderFieldsFragment } from "../../../../../../../graphql-types"
import * as styles from "./submenus.module.scss"
import { useSlugs } from "../../../../../context/slugs-context"
import RightArrow from "../../../../../../../static/vectors/v3/right-arrow.svg"

type RenderProps = {
  submenus: V3SubmenuHeaderFieldsFragment[]
  onClick: (index: number) => void
}

const Submenus: React.FC<RenderProps> = ({ submenus, onClick }) => {
  const { buildInternalLink } = useSlugs()

  return (
    <div className={styles.submenus}>
      {submenus.map((submenu, index) =>
        submenu.containsMultipleLinks ? (
          <div className={styles.multipleLinks}>
            <div className={styles.submenuTitle} onClick={() => onClick(index)} key={submenu.title}>
              <span className={styles.spanAnimated}>{submenu.title}</span>
            </div>
            <div>
              <ReactSVG src={RightArrow} />
            </div>
          </div>
        ) : (
          <a href={buildInternalLink(submenu.uniqueLink)} className={styles.submenuTitle} key={submenu.title}>
            <span className={styles.spanAnimated}>{submenu.title}</span>
          </a>
        )
      )}
    </div>
  )
}

export const fragment = graphql`
  fragment V3SubmenuHeaderFields on DatoCmsV3SubmenuHeader {
    id
    title
    containsMultipleLinks
    multipleLinks {
      ...InternalExternalLinkBlockFields
    }
    uniqueLink {
      __typename
      ... on DatoCmsParentPage {
        image {
          alt
          url
        }
        slug
      }
      ... on DatoCmsGeneratedPage {
        slug
      }
      ... on DatoCmsPage {
        slug
      }
    }
  }
`

export default Submenus
