import React, { useRef } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { V3TextCarouselFieldsFragment } from "../../../../../graphql-types"

import * as styles from "./v3-text-carousel.module.scss"
import { useAssociatedColor, useIsMobile } from "../../../hooks"
import ScrollArrow from "../../tools/scroll-arrow/scroll-arrow"

type RenderProps = {
  data: V3TextCarouselFieldsFragment
}

const V3TextCarousel: React.FC<RenderProps> = ({ data }) => {
  const isMobile = useIsMobile()
  const carousel = useRef(null)
  const scrollToCard = (scrollDirection: number) => {
    const itemWidth = carousel.current.children[0].clientWidth + 4
    carousel.current.scrollBy({
      left: itemWidth * scrollDirection,
      top: 0,
      behavior: "smooth",
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.carousel} ref={carousel}>
        {data.listOfTexts.map((elt, index) => (
          <div key={index} className={styles.carouselElt}>
            <ReactSVG
              src={"/vectors/v3/text-to-carousel/card1.svg"}
              className={styles.backgroundSvg}
              afterInjection={svg => {
                svg
                  .querySelectorAll("#colors rect")
                  .forEach(group => group.setAttribute("style", `fill: ${elt.backgroundColor.hex}`))
                svg
                  .querySelectorAll("#colors path")
                  .forEach(group => group.setAttribute("style", `fill: ${useAssociatedColor(elt.backgroundColor.hex)}`))
              }}
            />

            <div className={styles.content} style={{ color: elt.textColor.hex }}>
              <span className={styles.title}>{elt.title}</span>
              <span className={styles.text}>{elt.text}</span>
            </div>
          </div>
        ))}
      </div>
      {!isMobile && data.listOfTexts.length > 4 && (
        <div className={styles.arrowsContainer}>
          <ScrollArrow direction="left" onClick={() => scrollToCard(-1)} />
          <ScrollArrow direction="right" onClick={() => scrollToCard(1)} />
        </div>
      )}
    </div>
  )
}

export const fragment = graphql`
  fragment V3TextCarouselFields on DatoCmsV3TextsCarousel {
    __typename
    listOfTexts {
      title
      text
      textColor {
        hex
      }
      backgroundColor {
        hex
      }
    }
  }
`

export default V3TextCarousel
