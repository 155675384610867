import React from "react"
import { graphql } from "gatsby"
import { TransportModeFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./transport-mode.module.scss"
import { DisplayImage } from "../../display-image/display-image"

type RenderProps = {
  data: TransportModeFieldsFragment
}

const TransportMode: React.FC<RenderProps> = ({ data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.emptyLeftPart} />
      <div className={styles.transportModesWrapper}>
        <h2 className={styles.transportModesTitle}>{data.title}</h2>
        <div className={styles.transportModesContainer}>
          <ul className={styles.transportModes}>
            {data?.transportMethodContent?.transportModeKeolis.map((methodTransport, index) => (
              <a
                className={styles.transportModeElement}
                key={index}
                href={methodTransport.transportModePage && methodTransport.transportModePage.slug}
              >
                {methodTransport && (
                  <DisplayImage
                    image={methodTransport.transportModeIcon}
                    svgStyle={styles.pictogram}
                    imgStyle={styles.pictogram}
                    pictogramColor="#FFFFFF"
                  />
                )}
                <span className={styles.transportMethodName}>{methodTransport.transportModeName}</span>
              </a>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment TransportModeFields on DatoCmsTransportMethodContent {
    __typename
    id
    title
    transportMethodContent {
      transportModeKeolis {
        transportModeName
        transportModeIcon {
          alt
          url
          format
        }
        transportModePage {
          slug
        }
      }
    }
  }
`

export default TransportMode
