import React from "react"
import { graphql } from "gatsby"
import { V3CapsuleHeaderFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./capsule.module.scss"
import { useSlugs } from "../../../../context/slugs-context"

type RenderProps = {
  capsule: V3CapsuleHeaderFieldsFragment
}

const Capsule: React.FC<RenderProps> = ({ capsule }) => {
  const { getInternalExternalLink } = useSlugs()

  return (
    <div className={styles.capsule} style={{ "--color": capsule.color.hex } as React.CSSProperties}>
      <a href={getInternalExternalLink(capsule.redirectLink)} className={styles.title}>
        {capsule.title}
      </a>
    </div>
  )
}

export const fragment = graphql`
  fragment V3CapsuleHeaderFields on DatoCmsV3CapsuleHeader {
    id
    title
    color {
      hex
    }
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
  }
`

export default Capsule
