import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, NewsPageQuery } from "../../../../graphql-types"
import * as config from "../../../../config"
import * as styles from "../type-page/type-page.module.scss"
import * as globalStyles from "../../../global.module.scss"
import { useFormatLocale, useIsMobile, useSlugValue } from "../../hooks"
import Layout from "../../components/layout/layout"
import Banner from "../../components/banner/banner"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import MetaData from "../../components/tools/meta-data/meta-data"
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich_dato_cms_content"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: NewsPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
    allSlugLocales: { locale: string; value: string }[]
  }
}

const News: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const page = data.datoCmsNews
  const currentRoute = `${useSlugValue(
    pageContext.slugs._allNewsroomPageSlugLocales,
    pageContext.locale
  )}/${useSlugValue(pageContext.slugs._allNewsPageSlugLocales, pageContext.locale)}`

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={currentRoute}
      pageTemplate="news"
      allSlugLocales={pageContext.allSlugLocales}
    >
      <Banner title={page.title} image={page.image} />

      <section className={`${globalStyles.typePageContainer} ${styles.metaData}`}>
        {!isMobile && (
          <Breadcrumb
            title={page.title}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={[
              {
                id: "newsroom",
                title: data.datoCmsNewsroom.newsroomPageTitle,
                isInternalPage: true,
                internalLink: {
                  __typename: "DatoCmsGeneratedPage",
                  slug: useSlugValue(pageContext.slugs._allNewsroomPageSlugLocales, pageContext.locale),
                },
              },
              {
                id: "all-news",
                title: data.datoCmsNewsroom.allNewsPageTitle,
                isInternalPage: true,
                internalLink: {
                  __typename: "DatoCmsGeneratedPage",
                  slug: `${useSlugValue(pageContext.slugs._allNewsroomPageSlugLocales, pageContext.locale)}/${useSlugValue(pageContext.slugs._allNewsPageSlugLocales, pageContext.locale)}`,
                },
              },
            ]}
          />
        )}

        <MetaData
          socialNetworks={data.datoCmsGlobalConfiguration.v3SocialNetworks}
          shareLabel={data.datoCmsGlobalConfiguration.v3ShareLabel}
          mailSubject={`${page.title} | ${config.site_name}`}
          publicationDate={page.publicationDate}
        />
      </section>

      <RichDatoCmsContent data={page.richContent} />
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsNews.titleTag}
      description={data.datoCmsNews.metaDescription}
      noindex={data.datoCmsNews.noindex}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <>
        <link
          rel="canonical"
          href={data.datoCmsNews.isCanonical ? data.datoCmsNews.urlCanonical : window.location.href}
        />
        <HrefLangLinks
          defaultDomain={window.location.origin}
          defaultLocale={pageContext.defaultLocale}
          slugs={[
            pageContext.slugs._allNewsroomPageSlugLocales,
            pageContext.slugs._allNewsPageSlugLocales,
            pageContext.allSlugLocales,
          ]}
        />
      </>
    )}
  </>
)

export const pageQuery = graphql`
  query NewsPage($locale: String!, $id: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
      v3ShareLabel
      v3SocialNetworks {
        ...V3SocialNetworkFields
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsNews(locale: $locale, id: { eq: $id }) {
      titleTag
      metaDescription
      noindex
      isCanonical
      urlCanonical
      title
      image {
        alt
        url(imgixParams: { w: "534", h: "400", fit: "crop", auto: "format" })
      }
      publicationDate
      richContent {
        ...RichContentFields
      }
    }
    datoCmsNewsroom(locale: $locale) {
      newsroomPageTitle
      allNewsPageTitle
    }
  }
`

export default News
