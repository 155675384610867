import React, { useState } from "react"
import magnifyingGlass from "../../../../static/vectors/magnifying_glass.svg"
import * as styles from "./search-bar.module.scss"
import { ReactSVG } from "react-svg"

type RenderProps = {
  data: string[]
  placeholder?: string
  onResultClick: (result: string) => void
}

const SearchBar: React.FC<RenderProps> = ({ data, placeholder, onResultClick }) => {
  const [query, setQuery] = useState("")
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number | null>(null)

  const normalizeString = (str: string) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  }

  const handleInputChange = event => {
    const input = event.target.value
    setQuery(input)

    if (input.length > 0) {
      const filteredSuggestions = data.filter(item => normalizeString(item).startsWith(normalizeString(input))).sort()
      setSuggestions(filteredSuggestions)
      setActiveSuggestionIndex(null)
    } else {
      setSuggestions([])
    }
  }

  const handleKeyDown = event => {
    if (event.key === "ArrowDown") {
      setActiveSuggestionIndex(prevIndex =>
        prevIndex === null || prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1
      )
    } else if (event.key === "ArrowUp") {
      setActiveSuggestionIndex(prevIndex =>
        prevIndex === null || prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      )
    } else if (event.key === "Enter" && activeSuggestionIndex !== null) {
      handleResultClick(suggestions[activeSuggestionIndex])
    }
  }

  const handleResultClick = result => {
    setQuery("")
    setSuggestions([])
    setActiveSuggestionIndex(null)
    onResultClick(result)
  }

  return (
    <div className={styles.searchBar}>
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder ?? "Search"}
        />
        <ReactSVG src={magnifyingGlass} aria-hidden={true} />
      </div>
      {suggestions.length > 0 && (
        <ul className={styles.suggestions}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={`${styles.suggestion} ${activeSuggestionIndex === index ? styles.active : ""}`}
              onClick={() => handleResultClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default SearchBar
