import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { V3MenuHeaderFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./menu.module.scss"
import Submenus from "./submenus/submenus"
import Details from "./details/details"
import RightArrow from "../../../../../../static/vectors/v3/right-arrow.svg"

type RenderProps = {
  menu: V3MenuHeaderFieldsFragment
  onClick: (e: React.MouseEvent<HTMLAnchorElement>, menuId: string) => void
  isMenuOpened: boolean
}

const Menu: React.FC<RenderProps> = ({ menu, onClick, isMenuOpened }) => {
  const [indexSubmenu, setIndexSubmenu] = React.useState(-1)

  const handleSubmenuClick = (index: number) => {
    setIndexSubmenu(indexSubmenu === index ? -1 : index)
  }

  return (
    <li>
      <a href="#" className={styles.mainTitle} onClick={e => onClick(e, menu.id)}>
        {menu.title}
        <div className={styles.arrow}>
          <ReactSVG src={RightArrow} />
        </div>
      </a>
      <div className={`${styles.menu} ${isMenuOpened ? styles.menuOpened : ""}`}>
        <div className={styles.menuTitle}>{menu.title}</div>
        <div className={styles.container}>
          <Submenus submenus={menu.submenus} onClick={handleSubmenuClick} />
          {indexSubmenu >= 0 && <Details links={menu.submenus[indexSubmenu].multipleLinks} />}
        </div>
      </div>
    </li>
  )
}

export const fragment = graphql`
  fragment V3MenuHeaderFields on DatoCmsV3MenuHeader {
    id
    title
    submenus {
      ...V3SubmenuHeaderFields
    }
  }
`

export default Menu
