import React from "react"
import { ReactSVG } from "react-svg"
import { InternalExternalLinkBlockFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./card.module.scss"
import { useSlugs } from "../../../context/slugs-context"
import Capsule from "../../tools/filter-bar/capsule/capsule"

type RenderProps = {
  title: string
  backgroundSvg: string
  backgroundColor?: string
  backgroundColorBis?: string
  textColor: string
  buttonText: string
  redirectLink: InternalExternalLinkBlockFieldsFragment
}

const Card: React.FC<RenderProps> = ({
  title,
  backgroundSvg,
  backgroundColor,
  backgroundColorBis,
  textColor,
  buttonText,
  redirectLink,
}) => {
  const { getInternalExternalLink } = useSlugs()
  const style = {
    "--text-color": textColor,
    "--background-color": backgroundColor,
    "--background-color-bis": backgroundColorBis,
  } as React.CSSProperties

  return (
    <div className={styles.card} style={style}>
      <ReactSVG
        src={backgroundSvg}
        beforeInjection={svg => svg.setAttribute("preserveAspectRatio", "none")}
        afterInjection={svg => {
          svg.querySelector("rect").style.fill = backgroundColor
          const paths = svg.querySelectorAll("path")
          paths.forEach(path => {
            path.style.fill = backgroundColorBis
          })
        }}
      />

      <div className={styles.cardContent}>
        <div></div> {/*The tag will be here in v3.1*/}
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        <div className={styles.buttonContainer}>
          <a href={getInternalExternalLink(redirectLink)} target={redirectLink.isInternalPage ? "_self" : "_blank"}>
            <Capsule title={buttonText} isSelected={false} special={false} className={styles.button} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Card
