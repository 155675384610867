import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { NetworkContactFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./network-contact.module.scss"
import TwitterHovered from "../../../../../static/vectors/twitter_hovered.svg"
import FacebookHovered from "../../../../../static/vectors/facebook_hovered.svg"
import LinkedInHovered from "../../../../../static/vectors/linkedin_hovered.svg"
import InstagramHovered from "../../../../../static/vectors/instagram_hovered.svg"

type RenderProps = {
  data: NetworkContactFieldsFragment
}

const NetworkContact: React.FC<RenderProps> = ({ data }) => {
  return (
    <div className={styles.networkContactWrapper}>
      <div className={styles.leftContent}></div>
      <div className={styles.rightContent}>
        <h2 className={styles.blockTitle}>{data.blockTitle}</h2>
        {data.urlWebSite && (
          <div className={styles.buttonWrapper}>
            <a href={data.urlWebSite} className={styles.lineLink} target="_blank">
              {data.buttonText}
            </a>
          </div>
        )}
        {data.subtitle && <h3 className={styles.subtitle}>{data.subtitle}</h3>}
        <div className={styles.socialMedia}>
          <>
            {data.facebook && (
              <a
                href={data && data.urlToFacebook && data.urlToFacebook.url}
                target="_blank"
                className={styles.socialNetworkLink}
              >
                <ReactSVG src={FacebookHovered} className={styles.pictogram} aria-hidden={true} />
              </a>
            )}
          </>
          <>
            {data.twitter && (
              <a
                href={data && data.urlToTwitter && data.urlToTwitter.url}
                target="_blank"
                className={styles.socialNetworkLink}
              >
                <ReactSVG src={TwitterHovered} className={styles.pictogram} aria-hidden={true} />
              </a>
            )}
          </>
          <>
            {data.linkedin && (
              <a
                href={data && data.urlToLinkedin && data.urlToLinkedin.url}
                target="_blank"
                className={styles.socialNetworkLink}
              >
                <ReactSVG src={LinkedInHovered} className={styles.pictogram} aria-hidden={true} />
              </a>
            )}
          </>
          <>
            {data.instagram && (
              <a
                href={data && data.urlToInstagram && data.urlToInstagram.url}
                target="_blank"
                className={styles.socialNetworkLink}
              >
                <ReactSVG src={InstagramHovered} className={styles.pictogram} aria-hidden={true} />
              </a>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment NetworkContactFields on DatoCmsContactNetwork {
    __typename
    id
    blockTitle
    urlWebSite
    buttonText
    subtitle
    instagram
    twitter
    facebook
    linkedin
    urlToInstagram {
      url
    }
    urlToTwitter {
      url
    }
    urlToFacebook {
      url
    }
    urlToLinkedin {
      url
    }
  }
`

export default NetworkContact
