import React from "react"
import { graphql } from "gatsby"
import { LegalDocumentsColumnFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./legal-documents-column.module.scss"
import { useSlugs } from "../../../../context/slugs-context"

type RenderProps = {
  column: LegalDocumentsColumnFieldsFragment[]
}

const LegalDocumentsColumn: React.FC<RenderProps> = ({ column }) => {
  const { getInternalExternalLink } = useSlugs()

  return (
    <ul className={styles.column}>
      {column.map((item, index) => (
        <li key={index}>
          {item.__typename === "DatoCmsInternalExternalLinkBlock" ? (
            <a href={getInternalExternalLink(item)} className={styles.link} key={index}>
              {item.title}
            </a>
          ) : (
            <a href="javascript:Didomi.preferences.show()" className={styles.link} key={index}>
              {item.footerLabel}
            </a>
          )}
        </li>
      ))}
    </ul>
  )
}

export const fragment = graphql`
  fragment LegalDocumentsColumnFields on DatoCmsUnionForDatoCmsFooterLegalDocumentsColumn {
    __typename
    ... on DatoCmsV3BannerDidomi {
      footerLabel
    }
    ... on DatoCmsInternalExternalLinkBlock {
      ...InternalExternalLinkBlockFields
    }
  }
`

export default LegalDocumentsColumn
