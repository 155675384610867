import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { RelatedContentExternalFieldsFragment, RelatedContentPdfFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./external-block.module.scss"
import UserJourneyButtonArrow from "../../../../../../static/vectors/user_journey_button_arrow.svg"
import { DisplayImage } from "../../../display-image/display-image"

type RenderProps = {
  data: RelatedContentExternalFieldsFragment | RelatedContentPdfFieldsFragment
}

const ExternalBlock: React.FC<RenderProps> = ({ data }) => {
  const url = (data as RelatedContentPdfFieldsFragment).pdf
    ? (data as RelatedContentPdfFieldsFragment).pdf.url
    : (data as RelatedContentExternalFieldsFragment).url
  const description = data.description.length < 200 ? data.description : `${data.description.substring(0, 200)}...`

  return (
    <a href={url} target="_blank" className={styles.link}>
      <div className={styles.card}>
        <DisplayImage image={data.image} svgStyle={styles.pictogram} imgStyle={styles.picture} />
        <div className={styles.cardContent}>
          {data.tag && <div className={styles.tag}>{data.tag}</div>}
          <div className={styles.title}>{data.title}</div>
          <div className={styles.description}>{description}</div>
          <div className={styles.cardFooter}>
            <ReactSVG src={UserJourneyButtonArrow} className={styles.arrow} aria-hidden={true} />
          </div>
        </div>
      </div>
    </a>
  )
}

export const fragments = graphql`
  fragment RelatedContentExternalFields on DatoCmsRelatedContentExternal {
    url
    image {
      alt
      format
      url
    }
    title
    description
    tag
  }

  fragment RelatedContentPdfFields on DatoCmsRelatedContentPdf {
    pdf {
      url
    }
    image {
      alt
      format
      url
    }
    title
    description
    tag
  }
`

export default ExternalBlock
