// extracted by mini-css-extract-plugin
export var bottomNavigator = "slider-module--bottomNavigator--9c4ae";
export var container = "slider-module--container--f2ae3";
export var content = "slider-module--content--73198";
export var img = "slider-module--img--60c39";
export var leftChevron = "slider-module--leftChevron--91cb3";
export var overImageLayer = "slider-module--overImageLayer--81c9c";
export var radioButton = "slider-module--radioButton--28c71";
export var radioButtonActive = "slider-module--radioButtonActive--f8533";
export var rightChevron = "slider-module--rightChevron--e1897";
export var svgContainer = "slider-module--svgContainer--bc32e";