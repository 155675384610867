import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, NotFoundPageQuery } from "../../../../graphql-types"
import * as styles from "./404.module.scss"
import { useFormatLocale, useIsMobile } from "../../hooks"
import Layout from "../../components/layout/layout"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich_dato_cms_content"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: NotFoundPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

const NotFoundPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()

  if (typeof window !== "undefined") {
    return (
      <Layout
        pageContext={pageContext}
        location={location}
        header={data.datoCmsV3Header}
        footer={data.datoCmsFooter}
        subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
        contactButton={data.datoCmsV3ContactButton}
        currentRoute={window.location.pathname}
        pageTemplate="other"
      >
        <section className={styles.container}>
          {!isMobile && <Breadcrumb title="404" homeLabel={data.datoCmsGlobalConfiguration.homePageTitle} />}

          <div className={styles.content}>
            <div className={styles.textContainer}>
              <h1 className={styles.title}>{data.datoCmsPageNotFound.title}</h1>
              <div className={styles.structuredText}>
                <RichDatoCmsContent data={data.datoCmsPageNotFound.secondaryMessage} />
              </div>
              <a
                href={
                  pageContext.locale === pageContext.defaultLocale ? "/" : `/${useFormatLocale(pageContext.locale)}`
                }
                className={styles.button}
              >
                {data.datoCmsPageNotFound.buttonText}
              </a>
            </div>

            <img
              src={data.datoCmsPageNotFound.image.url}
              alt={data.datoCmsPageNotFound.image.alt || ""}
              className={styles.photo}
            />
          </div>
        </section>
      </Layout>
    )
  }

  return <></>
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title="404: Not Found"
      description={data.datoCmsGlobalConfiguration.metaDescription}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <HrefLangLinks
        defaultDomain={window.location.origin}
        defaultLocale={pageContext.defaultLocale}
        manualSlugs="404/"
      />
    )}
  </>
)

export const pageQuery = graphql`
  query NotFoundPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      metaDescription
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
      homePageTitle
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsPageNotFound(locale: $locale) {
      title
      buttonText
      secondaryMessage {
        ... on DatoCmsStructuredText {
          __typename
          structuredText {
            value
          }
        }
      }
      image {
        alt
        url
      }
    }
  }
`

export default NotFoundPage
