// extracted by mini-css-extract-plugin
export var arrow = "internal-block-module--arrow--c64e4";
export var card = "internal-block-module--card--3f797";
export var cardContent = "internal-block-module--cardContent--49ef2";
export var cardFooter = "internal-block-module--cardFooter--a4b30";
export var description = "internal-block-module--description--46053";
export var link = "internal-block-module--link--5215a";
export var pictogram = "internal-block-module--pictogram--a24d2";
export var picture = "internal-block-module--picture--54688";
export var tag = "internal-block-module--tag--2d689";
export var title = "internal-block-module--title--a5866";