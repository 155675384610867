import React, { useState } from "react"
import Capsule from "./capsule/capsule"
import * as styles from "./filter-bar.module.scss"

type RenderProps = {
  capsules: {
    label: string
    onClick?: () => void
    url?: string
    isActive?: boolean
    special?: boolean
  }[]
}

const FilterBar: React.FC<RenderProps> = ({ capsules }) => {
  return (
    <div className={styles.filterBar}>
      <div className={styles.capsules}>
        {capsules?.map((capsule, index) => (
          <Capsule
            title={capsule.label}
            isSelected={capsule.isActive}
            className={capsule.special ? styles.specialCapsule : ""}
            special={capsule.special}
            onClick={capsule.onClick}
            link={capsule.url && { url: capsule.url, isInternal: true }}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export default FilterBar
