import { DatoCmsSlugsConfiguration } from "../../../graphql-types"
import { useFormatLocale } from "./use-format-locale"

/**
 * Change page language
 *
 * @param slugs all the slugs of the website all languages included
 * @param currentRoute the current route (ex: /newsroom/press-release)
 * @param allSlugLocales the current end of slug translated in all languages
 * @param otherLocale the new language locale
 * @param defaultLocale the default locale of the page
 */
export const useChangeLanguage = (
  slugs: DatoCmsSlugsConfiguration,
  currentRoute: string,
  allSlugLocales: { locale: string; value: string }[],
  otherLocale: string,
  defaultLocale: string
) => {
  const routeArray = currentRoute.split("/")
  let relatedSlug = ""

  routeArray.forEach(route => {
    for (const key in slugs) {
      if (Array.isArray(slugs[key])) {
        const isRelatedSlug = slugs[key].filter(slug => slug.value === route)
        if (isRelatedSlug.length > 0) {
          const slugByLocale = slugs[key].filter(slug => useFormatLocale(slug.locale) === otherLocale)
          if (slugByLocale.length > 0) {
            if (relatedSlug.length > 0) relatedSlug += "/"
            relatedSlug += slugByLocale[0].value
          }
        }
      }
    }
  })

  relatedSlug = otherLocale === defaultLocale ? `/${relatedSlug}` : `/${otherLocale}/${relatedSlug}`

  if (allSlugLocales) {
    if (relatedSlug.length > 1 && relatedSlug[relatedSlug.length - 1] === "/") {
      relatedSlug = relatedSlug.slice(0, -1)
    }

    const articleSlug = allSlugLocales.filter(slug => useFormatLocale(slug.locale) === otherLocale)
    if (articleSlug && articleSlug[0]) {
      if (relatedSlug.slice(-1) !== "/") {
        relatedSlug += "/"
      }
      relatedSlug += articleSlug[0].value
    }
  }

  return relatedSlug
}
